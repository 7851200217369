import React from 'react';
import { ModalTitleCenter } from '../../components/Modal/modalStyles';
import { ModalContainer, ModalMainText, ModalText } from './homeStyles';

const HowToPay = () => {
  return (
    <ModalContainer>
      <ModalTitleCenter>Formas de Pago</ModalTitleCenter>
      <ModalMainText>Podés pagar a través de la plataforma de pago MercadoPago con:</ModalMainText>
      <ModalText>- Tarjeta de crédito.</ModalText>
      <ModalText>- Tarjeta de Débito</ModalText>
      <ModalText>- Con saldo pre-cargado en tu cuenta de MercadoPago. </ModalText>
      <ModalText>- Transferencia.</ModalText>
    </ModalContainer>
  );
};

export default HowToPay;

/*
En efectivo: imprimiendo el cupón de pago y acercándote a cualquier sucursal de Pago Fácil, Rapipago o Bapropagos (Provincia Pagos)
(Opción deshabilitada momentáneamente).
*/
