import React, { useEffect, useContext } from 'react';
// hoks
import { useForm } from 'react-hook-form';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { subscribeStart, cleanLogs } from './newsletterActions';
// components

import Input from '../../components/Base/Input/Input';
import Button from '../../components/Base/Button/Button';
// styles
import {
  FormModalContainer,
  FormModalLabel,
  ModalTitleCenter,
  FormModalSubContainer
} from '../../components/Modal/modalStyles';
// context
import { ModalContext } from '../../components/Modal/Modal';
// validations
import { validateEmail } from '../../utils/validations';

const Newsletter = () => {
  const { register, handleSubmit, setError, errors } = useForm();
  const dispatch = useDispatch();

  const { loading, error } = useSelector(({ newsletter }) => newsletter);
  const { user } = useSelector(({ auth }) => auth);

  const { closeModal } = useContext(ModalContext);

  const onSubmit = ({ email }) => {
    const isValid = validateEmail(email);

    if (isValid.valid) {
      dispatch(subscribeStart({ email, closeModal }));
    } else setError('error', '', isValid.message);
  };

  useEffect(() => {
    return () => dispatch(cleanLogs());
  }, [dispatch]);

  return (
    <>
      <ModalTitleCenter>NewsLetter</ModalTitleCenter>
      <FormModalContainer onSubmit={handleSubmit(onSubmit)}>
        <FormModalSubContainer disabled={loading}>
          <FormModalLabel>
            <Input
              ref={register}
              name='email'
              type='text'
              placeholder='Correo eletrónico'
              error={errors.error?.message || error}
              defaultValue={user?.email}
              disabled={user?.email}
            />
          </FormModalLabel>

          <Button title='Suscribirme' isLoading={loading} />
        </FormModalSubContainer>
      </FormModalContainer>
    </>
  );
};

export default Newsletter;
