import styled, { css } from 'styled-components';
import { breakpoints, breakpointsMin } from '../../assets/constants/breakpoints';
import logo from '../../assets/logo/logo.PNG';
import isotipo from '../../assets/logo/logo.PNG';
import { Link } from 'react-router-dom';

const navWidth = '180px';
const navWidthXXL = '155px';

export const FullContainer = styled.div`
  display: flex;
  padding: 3rem 2rem;
  height: 100vh;
  max-width: 2500px;
  margin: auto;

  @media screen and (max-width: ${breakpoints.xl}) {
    flex-direction: column;
    padding: 1rem;
    display: grid;
    grid-template-areas: 'nav-left nav-right' 'main main';
    grid-template-rows: calc(55px + 2rem) 1fr;
  }

  @media screen and (max-height: 500px) {
    padding: 1rem 2rem;
  }
`;

// ========================================================================================================== //
// ================================================= NAVBAR  ================================================ //
// ========================================================================================================== //

export const Nav = styled.nav`
  height: 100%;
  position: relative;

  @media screen and (min-width: ${breakpointsMin.xl}) {
    width: ${navWidthXXL};
  }

  @media screen and (min-width: ${breakpointsMin.xxl}) {
    width: ${navWidth};
  }

  @media screen and (max-width: ${breakpoints.xxl}) {
    display: flex;
    align-items: center;
    padding-bottom: 2rem;
  }

  @media screen and (max-width: ${breakpoints.xl}) {
    /* left */
    :nth-child(1) {
      grid-area: nav-left;
    }
    /* right */
    :nth-child(3) {
      grid-area: nav-right;
      justify-self: flex-end;
    }
  }
`;

export const NavSubcontainer = styled.div`
  position: relative;
  display: flex;
  /* align-items: center; */

  @media screen and (min-width: ${breakpointsMin.xl}) {
    position: fixed;

    flex-direction: column;
    justify-content: space-between;
    top: 3rem; /* full container padding */
    bottom: 3rem; /* full container padding */

    width: ${navWidthXXL};
  }

  @media screen and (min-width: ${breakpointsMin.xxl}) {
    width: ${navWidth};
    min-height: 350px;
  }

  @media screen and (max-height: 500px) {
    top: 1rem; /* full container padding */
    bottom: 1rem; /* full container padding */
  }
`;

export const NavbarTop = styled.div`
  ${({ position }) =>
    position === 'right' &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50px;
      margin-left: auto;
      @media screen and (max-width: ${breakpoints.xl}) {
        display: grid;
        grid-auto-flow: column;
        justify-content: flex-end;
        grid-gap: 2rem;
        margin: 0;
        width: auto;
      }
    `}
`;
export const NavbarBottom = styled(NavbarTop)`
  /* left and right */
  @media screen and (max-width: ${breakpoints.xl}) {
    display: none;
  }
`;

// ========================================================================================================== //
// ============================================== NAVBAR LEFT ============================================== //
// ========================================================================================================== //

export const Logo = styled.div`
  background-image: url(${logo});
  width: 100px;
  height: 91px;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;

  ${({ center }) =>
    center &&
    css`
      margin: auto;
    `}

  ${({ responsive }) =>
    responsive &&
    css`
      @media screen and (max-width: ${breakpoints.xl}) {
        background-image: url(${isotipo});
        width: 35px;
        height: 35.09px;
        margin: 0.5rem 0;
      }
    `}
`;

export const NavbarList = styled.ul`
  list-style: none;
  border-left: 1px solid rgba(var(--gray-rgb), 0.4);
  padding-left: 0;
  margin-top: 1rem;

  /* left */
  @media screen and (max-width: ${breakpoints.xl}) {
    display: none;
  }
`;

const navbarListItem = css`
  cursor: pointer;
  color: var(--gray);
  padding: 0.4rem 0.6rem;
  font-size: 0.65rem;
  line-height: 18.75px;
  letter-spacing: 3px;
  :hover {
    color: var(--primary);
  }

  @media screen and (max-width: ${breakpoints.xxl}) {
    font-size: 0.65rem;
  }
`;

export const NavbarListItem = styled.li`
  ${navbarListItem}
`;

export const NavbarListItemLink = styled(Link)`
  ${navbarListItem}
  display: block;
`;

export const NavbarBottomList = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const navbarBottomListItem = css`
  ${navbarListItem};
  font-size: 0.95rem;
  padding: 0.3rem 0;
  letter-spacing: normal;

  @media screen and (max-width: ${breakpoints.xxl}) {
    font-size: 0.9rem;
  }
`;

export const NavbarBottomListItem = styled.li`
  ${navbarBottomListItem};
`;

export const NavbarBottomListItemLink = styled(Link)`
  ${navbarBottomListItem};
  display: block;
`;

// ========================================================================================================== //
// ============================================== NAVBAR RIGHT ============================================== //
// ========================================================================================================== //

export const IconContainer = styled.div`
  ${({ bottomIcon }) => (bottomIcon ? 'margin-top: 2rem' : 'margin-bottom: 3rem')};
  cursor: pointer;
  position: relative;

  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  @media screen and (max-width: ${breakpoints.xxl}) {
    width: ${({ xxlSize }) => xxlSize}px;
    height: ${({ xxlSize }) => xxlSize}px;
  }

  @media screen and (max-width: ${breakpoints.xl}) {
    margin-bottom: 0;
  }
`;

export const BarsIconContainer = styled.div`
  @media screen and (min-width: ${breakpointsMin.xl}) {
    display: none;
  }
`;

export const ShoppingBagContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const ShoppingBagCount = styled.div`
  position: absolute;
  top: -10px;
  margin-left: 15px;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 20px;
  width: 20px;

  background-color: var(--primary);
  color: var(--white);

  border-radius: 100%;
  font-size: 0.8rem;
  user-select: none;
`;

export const ShoppingBagPrice = styled.p`
  color: var(--primary);
  position: absolute;
  bottom: -1.5rem;
  left: 50%;
  transform: translateX(-50%);
`;

// ========================================================================================================== //
// ============================================ NAVBAR RESPONSIVE =========================================== //
// ========================================================================================================== //

export const ResponsiveMenuContainer = styled.div``;

export const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  list-style: none;
  padding: 0;
`;

const ResponsiveMenuListItem = css`
  padding: 1rem;
  cursor: pointer;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  border-radius: 0.2rem;
  color: var(--black);
  :hover {
    background-color: var(--primary);

    color: #fff;
  }
`;

export const MenuListItem = styled.li`
  ${ResponsiveMenuListItem}
`;

export const MenuListItemLink = styled(Link)`
  ${ResponsiveMenuListItem}
`;

export const MenuListItemExternalLink = styled.a`
  ${ResponsiveMenuListItem}
`;
