import styled from 'styled-components';

export const ItemQuantityContainer = styled.div`
  display: flex;
  background-color: var(--light-2);
`;

export const ItemQuantityButton = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0.45rem 0.8rem;
  font-size: 1.1rem;
  outline: 0;
`;

export const ItemQuantityInput = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.45rem;
  min-width: 40px;
  font-size: 0.9rem;
`;
