import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import GlobalStyles from '../../globalStyles';
import AuthRoute from './AuthRoute';
// components
import LoaderPage from '../../components/Base/Loader/LoaderPage';
import Notifier from '../Notifier/Notifier';
import Navbar from '../../components/Navbar/Navbar';
import ErrorBoundary from './ErrorBoundary';
import Product from '../Products/Product';
import Combo from '../Combos/Combo';
import { Main } from './appStyles';
import Modal from '../../components/Modal/Modal';
// containers
const Home = lazy(() => import('../Home/Home'));
const ResetPassword = lazy(() => import('../Auth/ResetPassword'));
const ConfirmAccount = lazy(() => import('../Auth/ConfirmAccount'));
const EditProfile = lazy(() => import('../Auth/EditProfile'));
const EditPassword = lazy(() => import('../Auth/EditPassword'));
const Contact = lazy(() => import('../Contact/Contact'));
const Checkout = lazy(() => import('../Checkout/Checkout'));
const Orders = lazy(() => import('../Orders/Orders'));
const Order = lazy(() => import('../Orders/Order'));
const SearchOrder = lazy(() => import('../Orders/SearchOrder'));
const PaymentSuccess = lazy(() => import('../Orders/PaymentSuccess'));

export default () => (
  <ErrorBoundary>
    {console.log('nala', process.env)}
    {/* {!window.location.href.includes('confirm') && <Navbar />} */}
    <GlobalStyles />
    <Suspense fallback={<LoaderPage />}>
      <Modal>
        <Navbar>
          <Main>
            <Switch>
              <Route path='/' component={Home} exact />
              <AuthRoute path='/profile' component={EditProfile} exact />
              <Route path='/reset-password/:token' component={ResetPassword} exact />
              <Route path='/confirm' component={ConfirmAccount} exact />
              <Route path='/edit-password' component={EditPassword} exact />
              <Route path='/contact' component={Contact} exact />
              <Route path='/product/:slug' component={Product} exact />
              <Route path='/pack/:slug' component={Combo} exact />
              <Route path='/checkout' component={Checkout} exact />
              <AuthRoute path='/orders' component={Orders} exact />
              <Route path='/order/:id' component={Order} exact />
              <Route path='/search/order' component={SearchOrder} exact />
              <Route path='/payment-success' component={PaymentSuccess} exact />
              <Route component={() => <Redirect to='/' />} exact />
            </Switch>
          </Main>
        </Navbar>
      </Modal>
    </Suspense>
    <Notifier />
  </ErrorBoundary>
);
