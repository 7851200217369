import React, { useCallback, useContext } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { likeProductStart } from '../../containers/Favorites/favoritesActions';
// components
import Login from '../../containers/Auth/Login';
// styles
import { HeartContainer } from './productStyles';
// icons
import { ReactComponent as HeartIcon } from '../../assets/icons/heart-strong.svg';
import { ReactComponent as HeartSolidIcon } from '../../assets/icons/heart-solid.svg';
// modalsContext
import { ModalContext } from '../Modal/Modal';

const ProductLike = ({ productId }) => {
  const dispatch = useDispatch();

  const { isAuth, user } = useSelector(({ auth }) => auth);

  const { list, loading: favoriteLoading } = useSelector(
      ({ favorites }) => favorites
    ),
    isLiked = isAuth && list.some(({ product }) => product?._id === productId);

  const { setModalConfig } = useContext(ModalContext);

  const handleAddProductToFavorites = useCallback(() => {
    if (!isAuth) setModalConfig({ component: <Login /> });
    else {
      if (!favoriteLoading)
        dispatch(
          likeProductStart({
            productId: productId,
            userId: user._id,
            action: isLiked ? 'dislike' : 'like'
          })
        );
    }
  }, [
    isAuth,
    dispatch,
    user,
    isLiked,
    setModalConfig,
    productId,
    favoriteLoading
  ]);
  return (
    <HeartContainer
      isLoading={favoriteLoading}
      onClick={handleAddProductToFavorites}
      whileHover={{
        scale: 1.07
      }}
    >
      {isLiked ? (
        <HeartSolidIcon fill='var(--red)' width={20} />
      ) : (
        <HeartIcon fill='var(--dark-2)' width={20} />
      )}
    </HeartContainer>
  );
};

export default React.memo(ProductLike);
