import { all, fork, put, takeLatest } from 'redux-saga/effects';

import * as constants from './combosConstants';
import * as actions from './combosActions';
import * as services from './combosServices';

function* getCombos() {
  try {
    const { combos } = yield services.getCombos();
    if (combos) yield put(actions.getCombosSuccess({ list: combos }));
  } catch (err) {
    yield put(actions.getCombosFailure({ error: err.message }));
  }
}

function* getCombosSaga() {
  yield takeLatest(constants.GET_COMBOS_START, getCombos);
}

// combo
function* getCombo({ payload }) {
  try {
    const { combo } = yield services.getCombo(payload.slug);
    if (combo) yield put(actions.getComboSuccess({ combo }));
  } catch (err) {
    yield put(actions.getComboFailure({ error: err.message }));
  }
}

function* getComboSaga() {
  yield takeLatest(constants.GET_COMBO_START, getCombo);
}

export default function* combosSaga() {
  yield all([fork(getCombosSaga), fork(getComboSaga)]);
}
