import React, { useMemo } from 'react';
// redux
import { useDispatch } from 'react-redux';
import {
  addProductToCart,
  removeProductFromCart,
  removeProductItemFromCart,
} from '../../containers/Cart/cartActions';
// components
import ProductQuantity from '../ProductQuantity/ProductQuantity';
// styles
import {
  CartItemContainer,
  ItemImageContainer,
  ItemImage,
  ItemDataContainer,
  ItemName,
  ItemPrice,
  ItemDeleteIconContainer,
  ItemPriceThrough,
} from './cartStyles';
// icons
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import setOffer from '../../utils/setOffer';

const CartItem = ({ product, goToProductPage }) => {
  const dispatch = useDispatch();

  const onRemoveProduct = () => dispatch(removeProductItemFromCart({ product }));
  const onAddProduct = () => dispatch(addProductToCart({ product }));

  const outputPrice = useMemo(
    () => (offer, price) => {
      if (offer?._id && offer?.status === 'confirmed') {
        return (
          <>
            <ItemPriceThrough>${price}</ItemPriceThrough>$
            {setOffer(price, { type: offer.type, amount: offer.amount })}
          </>
        );
      } else {
        return '$' + price;
      }
    },
    []
  );

  return (
    <CartItemContainer>
      <ItemImageContainer>
        <ItemImage src={product?.images ? product?.images[0] : ''} />
      </ItemImageContainer>
      <ItemDataContainer>
        <ItemName onClick={goToProductPage}> {product?.name} </ItemName>
        <ItemPrice>{outputPrice(product?.offer, product?.price)} </ItemPrice>
        <ProductQuantity
          onRemoveProduct={onRemoveProduct}
          onAddProduct={onAddProduct}
          quantity={product?.quantity}
        />
      </ItemDataContainer>
      <ItemDeleteIconContainer>
        <TrashIcon
          height='22'
          width='22'
          fill='var(--red-2)'
          onClick={() => dispatch(removeProductFromCart({ product }))}
        />
      </ItemDeleteIconContainer>
    </CartItemContainer>
  );
};

export default CartItem;
