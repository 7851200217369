import * as constants from './combosConstants';

const initialState = {
  list: [],
  combo: {},
  liked: false,
  loading: true,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.GET_COMBOS_START:
    case constants.GET_COMBO_START:
      return { ...state, combo: {}, liked: false, loading: true, error: null };
    case constants.GET_COMBOS_SUCCESS:
      return {
        ...state,
        list: payload.list,
        loading: false,
        error: null,
      };
    case constants.GET_COMBO_SUCCESS:
      return {
        ...state,
        combo: payload.combo,
        loading: false,
      };
    case constants.GET_COMBO_FAILURE:
    case constants.GET_COMBOS_FAILURE:
      return { ...state, loading: false, error: payload.error };

    default:
      return state;
  }
};
