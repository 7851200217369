export const GET_ORDERS_START = 'app/Orders/GET_ORDERS_START';
export const GET_ORDERS_SUCCESS = 'app/Orders/GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAILURE = 'app/Orders/GET_ORDERS_FAILURE';

export const GET_ORDER_START = 'app/Orders/GET_ORDER_START';
export const GET_ORDER_SUCCESS = 'app/Orders/GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'app/Orders/GET_ORDER_FAILURE';

export const SEARCH_ORDER_START = 'app/Orders/SEARCH_ORDER_START';
export const SEARCH_ORDER_FAILURE = 'app/Orders/SEARCH_ORDER_FAILURE';
