import React, { useMemo, useContext, useCallback } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import {
  removeProductItemFromCart,
  addProductToCart
} from '../../containers/Cart/cartActions';
// styles
import {
  ProductHeaderContainer,
  ProductDataContainer,
  ProductTitle,
  ProductPrice,
  ProductCartOptionsContainer,
  ProductInfoListContainer,
  ProductInfoItemContainer,
  ProductInfoItemTitle,
  ProductInfoItemText,
  ProductPriceContainer
} from './productStyles';
// components
import ProductQuantity from '../ProductQuantity/ProductQuantity';
import Button from '../Base/Button/Button';
import ProductHeaderImage from './ProductHeaderImage';
import ProductLike from './ProductLike';
// utils
import setOffer from '../../utils/setOffer';
import { ItemPriceThrough } from '../Cart/cartStyles';
// modalsContext
import { ModalContext } from '../Modal/Modal';
import Cart from '../../containers/Cart/Cart';

const ProductHeader = ({ product }) => {
  const products = useSelector(({ cart }) => cart.list);

  const dispatch = useDispatch();

  const { setModalConfig } = useContext(ModalContext);

  const productCart = products.filter(({ _id }) => _id === product._id);

  const isCombo = useMemo(() => product?.type === 'Combo', [product]);

  const tags = useMemo(
    () =>
      isCombo
        ? product?.products
            ?.map(product => product?.tags.map(tag => tag.name))
            .flat(2)
        : product?.tags?.map(tag => tag.name),
    [isCombo, product]
  );

  const category = useMemo(
    () =>
      isCombo
        ? product?.products
            ?.map(product => product?.category?.name)
            .filter(cat => cat)
        : product?.category?.name,
    [isCombo, product]
  );

  const outputPrice = useMemo(
    () => (offer, price) => {
      if (offer?._id && offer?.status === 'confirmed') {
        return (
          <>
            <ItemPriceThrough style={{ fontSize: '1.3rem' }}>
              ${price}
            </ItemPriceThrough>
            ${setOffer(price, { type: offer.type, amount: offer.amount })}
          </>
        );
      } else {
        return '$' + price;
      }
    },
    []
  );

  const quantity = useMemo(
    () => (productCart?.length ? productCart[0]?.quantity : 0),
    [productCart]
  );

  const handleAddToCart = useCallback(() => {
    if (quantity === 0) {
      setModalConfig({ component: <Cart />, type: 'full-right' });
    }
    dispatch(addProductToCart({ product }));
  }, [quantity, setModalConfig, product, dispatch]);

  const handleRemoveFromCart = useCallback(
    () =>
      productCart?.length
        ? dispatch(removeProductItemFromCart({ product }))
        : null,
    [productCart, dispatch, product]
  );

  return (
    <>
      <ProductHeaderContainer>
        <ProductHeaderImage name={product?.name} images={product?.images} />

        <ProductDataContainer>
          <ProductTitle>{product.name && product.name.trim()}</ProductTitle>
          <ProductPriceContainer>
            <ProductPrice>
              {outputPrice(product?.offer, product?.price)}
            </ProductPrice>
            {!isCombo && <ProductLike productId={product._id} />}
          </ProductPriceContainer>

          {product?.stock && (
            <ProductCartOptionsContainer>
              <Button
                title='AGREGAR AL CARRITO'
                fullWidth={false}
                xxlSize='md'
                onClick={handleAddToCart}
              />

              <ProductQuantity
                onRemoveProduct={handleRemoveFromCart}
                onAddProduct={handleAddToCart}
                quantity={quantity}
                style={{ marginLeft: 20 }}
              />
            </ProductCartOptionsContainer>
          )}

          <ProductInfoListContainer>
            <ProductInfoItemContainer>
              <ProductInfoItemTitle>
                {isCombo ? 'Categorías:' : 'Categoría:'}
              </ProductInfoItemTitle>
              <ProductInfoItemText>
                {' '}
                {isCombo ? [...new Set(category)]?.join(', ') : category}
              </ProductInfoItemText>
            </ProductInfoItemContainer>

            <ProductInfoItemContainer>
              <ProductInfoItemTitle>Etiquetas:</ProductInfoItemTitle>
              <ProductInfoItemText>
                {isCombo ? [...new Set(tags)]?.join(', ') : tags?.join(', ')}
              </ProductInfoItemText>
            </ProductInfoItemContainer>

            <ProductInfoItemContainer>
              <ProductInfoItemTitle>Stock:</ProductInfoItemTitle>
              <ProductInfoItemText>
                {product?.stock ? (
                  'Disponible'
                ) : (
                  <span style={{ color: 'red' }}>No Disponible</span>
                )}
              </ProductInfoItemText>
            </ProductInfoItemContainer>
          </ProductInfoListContainer>
        </ProductDataContainer>
      </ProductHeaderContainer>
    </>
  );
};

export default React.memo(ProductHeader);
