import React, { useContext } from 'react';
// redux
import { useSelector } from 'react-redux';
// components
import Search from '../Search/Search';
import ResponsiveMenu from './ResponsiveMenu';
import Cart from '../../containers/Cart/Cart';
import Favorites from '../../containers/Favorites/Favorites';
// styles
import {
  Nav,
  NavSubcontainer,
  NavbarTop,
  NavbarBottom,
  IconContainer,
  ShoppingBagContainer,
  ShoppingBagCount,
  ShoppingBagPrice,
  BarsIconContainer
} from './navbarStyles';
// icons
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as ShoppingCart } from '../../assets/icons/shopping-cart.svg';
import { ReactComponent as HeartIcon } from '../../assets/icons/heart-strong.svg';
import { ReactComponent as BarsIcon } from '../../assets/icons/bars.svg';
import { ReactComponent as WspIcon } from '../../assets/icons/whatsapp.svg';
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram.svg';
// modal component
import { ModalContext } from '../Modal/Modal';
// utils
import { socialMedia } from '../../utils/socialMediaLinks';
import Login from '../../containers/Auth/Login';

const NavbarRight = () => {
  const { list, price, send } = useSelector(({ cart }) => cart);
  const { isAuth } = useSelector(({ auth }) => auth);
  const { setModalConfig } = useContext(ModalContext);

  return (
    <Nav>
      <NavSubcontainer>
        <NavbarTop position='right'>
          <IconContainer
            size='22'
            xxlSize='20'
            onClick={() =>
              setModalConfig({
                component: <Search />,
                type: 'full-right'
              })
            }
          >
            <SearchIcon fill='var(--dark)' />
          </IconContainer>

          <IconContainer
            size='25'
            xxlSize='22'
            onClick={() =>
              setModalConfig({
                component: <Cart />,
                type: 'full-right'
              })
            }
          >
            <ShoppingBagContainer>
              <ShoppingBagCount>{list.length}</ShoppingBagCount>
              <ShoppingCart fill='var(--dark)' />
            </ShoppingBagContainer>
            <ShoppingBagPrice>${price + (send ?? 0)}</ShoppingBagPrice>
          </IconContainer>
          <IconContainer
            size='25'
            xxlSize='22'
            onClick={() =>
              setModalConfig({
                component: !isAuth ? <Login /> : <Favorites />,
                type: !isAuth ? '' : 'full-right'
              })
            }
          >
            <HeartIcon fill='var(--dark)' />
          </IconContainer>

          <BarsIconContainer
            onClick={() =>
              setModalConfig({
                component: <ResponsiveMenu />,
                type: 'full-right'
              })
            }
          >
            <IconContainer size='20' xxlSize='20'>
              <BarsIcon fill='var(--dark)' />
            </IconContainer>
          </BarsIconContainer>
        </NavbarTop>
        <NavbarBottom position='right'>
          <IconContainer size='25' xxlSize='22' bottomIcon>
            <a
              href={socialMedia.whatsapp}
              target='_blank'
              rel='noopener noreferrer'
            >
              <WspIcon fill='var(--dark)' />
            </a>
          </IconContainer>
          <IconContainer size='25' xxlSize='22' bottomIcon>
            <a
              href={socialMedia.instagram}
              target='_blank'
              rel='noopener noreferrer'
            >
              <InstagramIcon fill='var(--dark)' />
            </a>
          </IconContainer>
        </NavbarBottom>
      </NavSubcontainer>
    </Nav>
  );
};

export default NavbarRight;
