import styled, { css, keyframes } from 'styled-components';
import { breakpoints } from '../../assets/constants/breakpoints';

/* Overlay animations */
const overlayAnimation = keyframes`
    from { background-color: rgba(var(--black-rgb), 0) } 
    to { background-color: rgba(var(--black-rgb), 0.73) }
`;
const overlayAnimationReverse = keyframes`
    from { background-color: rgba(var(--black-rgb), 0.73) }
    to { background-color: rgba(var(--black-rgb), 0) } 
`;

/* Overlay  */
export const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  display: flex;
  justify-content: ${({ positionX }) => positionX};
  align-items: ${({ positionY }) => positionY};
  padding: ${({ fullHeight }) => (fullHeight ? 0 : '1rem')};
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  /* animation */
  ${({ open, animationDuration }) => {
    return open === 0.5
      ? css`
          animation: ${overlayAnimationReverse} ${animationDuration}ms ease forwards;
        `
      : css`
          animation: ${overlayAnimation} ${animationDuration}ms ease forwards;
        `;
  }}
`;

/* Modal animations */
const modalSlideUp = keyframes`
    from { 
        opacity: 0;
        transform: translateY(10%);
    } 
    to {  
        opacity: 1;
        transform: translateY(0);
    }
`;
const modalSlideUpReverse = keyframes`
    from {  
        opacity: 1;
        transform: translateY(0);
    }
    to { 
        opacity: 0;
        transform: translateY(10%);
    } 
`;

const modalSlideRight = keyframes`
    from { transform: translateX(100%); }
    to { transform: translateX(0); }
`;
const modalSlideRightReverse = keyframes`
    from { transform: translateX(0); }
    to { transform: translateX(100%); }
`;

/* Modal */
export const ModalContainer = styled.div`
  position: fixed;
  z-index: 10000;
  background-color: #fff;
  transition: all 0.2s ease-in-out;

  ${({ fullHeight }) =>
    fullHeight
      ? css`
          height: 100vh;
          min-width: 500px;
          width: 20%;
          overflow-y: auto;
          padding: 70px 30px;

          @media screen and (max-width: ${breakpoints.sm}) {
            padding: 35px 16px 70px;
          }

          @media screen and (max-width: ${breakpoints.xs}) {
            min-width: 100%;
            width: 100%;
          }
        `
      : css`
          height: auto;
          border-radius: 0.5rem;
          width: 500px;
          max-width: 95%;
          padding: 1rem;
          box-shadow: 0 6px 9px #0005;
        `};

  /* animation */
  ${({ open, animation, animationDuration }) => {
    return open === 0.5
      ? css`
          animation: ${animation === 'slide-right' ? modalSlideRightReverse : modalSlideUpReverse}
            ${animationDuration}ms ease forwards;
        `
      : css`
          animation: ${animation === 'slide-right' ? modalSlideRight : modalSlideUp}
            ${animationDuration}ms ease forwards;
        `;
  }}
`;

export const CrossIconContainer = styled.div`
  padding: ${({ fullHeight }) => (fullHeight ? '30px' : '1rem')};
  position: fixed;
  top: 0;
  right: 0;
  cursor: pointer;

  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 20px;
  }
`;

// others
export const ModalTitle = styled.h2`
  font-size: 2rem;
  font-weight: normal;
`;
export const ModalTitleCenter = styled.h2`
  font-size: 1.7rem;
  font-weight: 400;
  text-align: center;
  margin: 1rem 0;
`;

// forms

export const FormModalContainer = styled.form`
  padding: 0 1rem 1rem;
`;
export const FormModalSubContainer = styled.fieldset`
  max-width: 100%;
  :disabled {
    input {
      opacity: 0.7;
    }
  }
`;

export const FormModalLabel = styled.div`
  margin: ${({ type }) => (type === 'textarea' ? '3.5rem' : '2.5rem')} 0 2.5rem;
`;

export const ModalWrapper = styled.div`
  outline: 0;
`;
