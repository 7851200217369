import React from 'react';
import {
  ItemQuantityContainer,
  ItemQuantityButton,
  ItemQuantityInput,
} from './productQuantityStyles';

const ProductQuantity = ({ onAddProduct, onRemoveProduct, quantity, style }) => {
  return (
    <ItemQuantityContainer style={style}>
      <ItemQuantityButton onClick={onRemoveProduct}>-</ItemQuantityButton>

      <ItemQuantityInput>{quantity}</ItemQuantityInput>

      <ItemQuantityButton onClick={onAddProduct}>+</ItemQuantityButton>
    </ItemQuantityContainer>
  );
};

export default React.memo(ProductQuantity);
