import styled from 'styled-components';
import { breakpoints } from '../../assets/constants/breakpoints';
import { Link } from 'react-router-dom';

export const AuthTextsContainer = styled.div`
  margin-bottom: 1rem;
`;

export const AuthTextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const AuthText = styled.p`
  color: var(--dark);
  font-size: 0.95rem;
  margin-right: 0.5rem;
`;

export const AuthTextLink = styled(AuthText)`
  color: var(--primary);
  cursor: pointer;
`;

// forgot password
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  margin: 10rem auto 0;
`;

export const ConfirmAccountContainer = styled.div`
  width: 100%;
  max-width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
`;

// PROFILE SECTION

export const ProfileContainer = styled.div`
  max-width: 800px;
  margin: 2rem auto 0;
`;

export const ProfileFormContainer = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem 3rem;
  margin-top: 3rem;

  label:nth-child(5),
  label:nth-child(6) {
    grid-column: span 2;
  }
  label {
    @media screen and (max-width: ${breakpoints.sm}) {
      grid-column: span 2;
    }
  }
`;

export const ProfileButtonContainer = styled.div`
  margin: 0.5rem 0;
  grid-column: span 2;
`;

export const ProfileLink = styled(Link)`
  color: var(--blue);
  display: block;
  margin-bottom: 1rem;
`;
