import React from 'react';
import { Btn, ButtonText, LoaderContainer } from './buttonStyles';
import { Loader } from '../Loader/Loader';

const Button = ({
  title,
  fullWidth = true,
  onClick,
  isLoading,
  style,
  size = {}, // keys: default, xxl, md, sm /// values: xs, sm, md, xl
}) => {
  // sizes
  let defaultSize = size.default || 'xl';
  let xxlSize = size.xxl || defaultSize;
  let mdSize = size.md || xxlSize;
  let smSize = size.sm || mdSize;
  const sizes = { size: defaultSize, xxl: xxlSize, md: mdSize, sm: smSize };

  return (
    <Btn
      fullWidth={fullWidth}
      onClick={!isLoading ? onClick : null}
      size={sizes}
      isLoading={isLoading}
      type='submit'
      style={style}
      whileHover={{
        scale: 1.025,
      }}
    >
      {isLoading ? (
        <LoaderContainer>
          <Loader type='balls' />
        </LoaderContainer>
      ) : (
        <ButtonText size={sizes}>{title}</ButtonText>
      )}
    </Btn>
  );
};

export default React.memo(Button);
