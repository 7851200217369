import React, { useCallback, useContext } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { likeProductStart } from '../../containers/Favorites/favoritesActions';
import { addProductToCart } from '../../containers/Cart/cartActions';
// components
import Cart from '../../containers/Cart/Cart';
import Login from '../../containers/Auth/Login';
import { Loader } from '../Base/Loader/Loader';
// Styles
import {
  ProductCirclesContainer,
  ProductCircle,
  ProductCircleTag
} from './productItemStyles';
// utils / packages / context
import { ModalContext } from '../Modal/Modal';
import { isMobile } from 'react-device-detect';
// icons
import { ReactComponent as HeartIcon } from '../../assets/icons/heart.svg';
import { ReactComponent as ShoppingPlusIcon } from '../../assets/icons/shopping-plus.svg';

const ProductItemCircles = ({ opts, product }) => {
  const {
    user: { _id: user },
    isAuth: auth
  } = useSelector(({ auth }) => auth);

  const { list, loading } = useSelector(({ favorites }) => favorites),
    isLiked =
      auth && list.some(({ product: item }) => item?._id === product._id);

  const dispatch = useDispatch();
  const isInCart = useSelector(({ cart }) =>
    cart.list.some(item => item._id === product._id)
  );

  const { setModalConfig } = useContext(ModalContext);

  const handleAddProductToCart = useCallback(
    e => {
      e.stopPropagation();
      setModalConfig({ component: <Cart />, type: 'full-right' });
      !isInCart &&
        dispatch(
          addProductToCart({
            product
          })
        );
    },
    [isInCart, setModalConfig, dispatch, product]
  );

  const handleAddProductToFavorites = useCallback(
    e => {
      e.stopPropagation();
      if (!loading) {
        if (!auth) setModalConfig({ component: <Login /> });
        else {
          dispatch(
            likeProductStart({
              productId: product._id,
              userId: user,
              action: isLiked ? 'dislike' : 'like'
            })
          );
        }
      }
    },
    [product, auth, dispatch, user, isLiked, loading, setModalConfig]
  );

  return (
    <ProductCirclesContainer isMobile={isMobile}>
      {opts.fav ? (
        <ProductCircle
          isMobile={isMobile}
          onClick={handleAddProductToFavorites}
          active={isLiked}
          isLoading={loading}
        >
          {loading ? (
            <Loader />
          ) : (
            <HeartIcon
              height='18'
              width='18'
              fill={isLiked ? '#fff' : '#444'}
            />
          )}

          {opts.tags && !loading ? (
            <ProductCircleTag isMobile={isMobile}>
              {!isLiked ? 'Añadir a favoritos' : 'Quitar de favoritos'}
            </ProductCircleTag>
          ) : null}
        </ProductCircle>
      ) : null}

      {opts.cart && product.stock ? (
        <ProductCircle
          isMobile={isMobile}
          onClick={handleAddProductToCart}
          active={isInCart}
        >
          <ShoppingPlusIcon
            height='20'
            width='20'
            fill={isInCart ? '#fff' : '#444'}
          />
          {opts.tags && product.stock ? (
            <ProductCircleTag isMobile={isMobile}>
              {isInCart ? 'Ver carrito' : 'Añadir al carrito'}
            </ProductCircleTag>
          ) : null}
        </ProductCircle>
      ) : null}
    </ProductCirclesContainer>
  );
};

export default React.memo(ProductItemCircles);
