import * as constants from './checkoutConstants';

export const sendOrderStart = payload => ({
  type: constants.SEND_ORDER_START,
  payload
});

export const sendOrderSuccess = payload => ({
  type: constants.SEND_ORDER_SUCCESS,
  payload
});

export const sendOrderFailure = payload => ({
  type: constants.SEND_ORDER_FAILURE,
  payload
});
