import React, { useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../containers/Auth/authActions';
// components
import Login from '../../containers/Auth/Login';
import Register from '../../containers/Auth/Register';
import Newsletter from '../../containers/Newsletter/Newsletter';
import Contact from '../../containers/Contact/Contact';
// context
import { ModalContext } from '../Modal/Modal';
// styles
import {
  ResponsiveMenuContainer,
  Logo,
  MenuList,
  MenuListItem,
  MenuListItemLink,
  MenuListItemExternalLink
} from './navbarStyles';
// utils
import { socialMedia } from '../../utils/socialMediaLinks';
import HowToPay from '../../containers/Home/HowToPay';
import Sends from '../../containers/Home/Sends';
import Refund from '../../containers/Home/Refund';
import WholeSale from '../../containers/Home/WholeSale';

const ResponsiveMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuth = useSelector(({ auth: { isAuth } }) => isAuth);
  const { setModalConfig, closeModal } = useContext(ModalContext);

  const handleOnClickItem = useCallback(
    fn => {
      closeModal();
      setTimeout(fn, 500);
    },
    [closeModal]
  );

  const handleOnGoHome = useCallback(
    () => handleOnClickItem(() => history.push('/')),
    [history, handleOnClickItem]
  );

  const handleOpenModal = useCallback(
    component => handleOnClickItem(() => setModalConfig({ component })),
    [handleOnClickItem, setModalConfig]
  );

  const handleLogout = useCallback(
    () => handleOnClickItem(() => dispatch(logout())),
    [handleOnClickItem, dispatch]
  );

  return (
    <ResponsiveMenuContainer>
      <Logo onClick={handleOnGoHome} center />
      <MenuList>
        <MenuListItem onClick={handleOnGoHome}>INICIO</MenuListItem>
        <MenuListItem onClick={() => handleOpenModal(<HowToPay />)}>
          FORMAS DE PAGO
        </MenuListItem>
        <MenuListItem onClick={() => handleOpenModal(<Sends />)}>
          ENVIOS
        </MenuListItem>
        <MenuListItem onClick={() => handleOpenModal(<Refund />)}>
          DEVOLUCIONES
        </MenuListItem>
        <MenuListItem onClick={() => handleOpenModal(<WholeSale />)}>
          POR MAYOR
        </MenuListItem>
        <MenuListItem onClick={() => handleOpenModal(<Contact />)}>
          CONTACTO
        </MenuListItem>
        <MenuListItemExternalLink
          onClick={closeModal}
          href={socialMedia.instagram}
          target='_blank'
          rel='noopener noreferrer'
        >
          INSTAGRAM
        </MenuListItemExternalLink>
        <MenuListItemExternalLink
          onClick={closeModal}
          href={socialMedia.whatsapp}
          target='_blank'
          rel='noopener noreferrer'
        >
          WHATSAPP
        </MenuListItemExternalLink>
        <MenuListItem onClick={() => handleOpenModal(<Newsletter />)}>
          SUSCRIBIRSE
        </MenuListItem>
        <MenuListItemLink to='/search/order' onClick={closeModal}>
          BUSCAR PEDIDO
        </MenuListItemLink>
        {!isAuth ? (
          <>
            <MenuListItem onClick={() => handleOpenModal(<Login />)}>
              INICIAR SESIÓN
            </MenuListItem>
            <MenuListItem onClick={() => handleOpenModal(<Register />)}>
              REGISTRARSE
            </MenuListItem>
          </>
        ) : (
          <>
            <MenuListItemLink to='/profile' onClick={closeModal}>
              MI PERFIL
            </MenuListItemLink>
            <MenuListItemLink to='/orders' onClick={closeModal}>
              MIS COMPRAS
            </MenuListItemLink>
            <MenuListItem onClick={handleLogout}>CERRAR SESIÓN</MenuListItem>
          </>
        )}
      </MenuList>
    </ResponsiveMenuContainer>
  );
};

export default React.memo(ResponsiveMenu);
