import * as constants from './authConstants';

export const registerStart = payload => ({
  type: constants.REGISTER_START,
  payload
});

export const registerSuccess = payload => ({
  type: constants.REGISTER_SUCCESS,
  payload
});

export const registerFailure = payload => ({
  type: constants.REGISTER_FAILURE,
  payload
});

// login
export const loginStart = payload => ({
  type: constants.LOGIN_START,
  payload
});

export const loginSuccess = payload => ({
  type: constants.LOGIN_SUCCESS,
  payload
});

export const loginFailure = payload => ({
  type: constants.LOGIN_FAILURE,
  payload
});

// forgot
export const forgotPasswordStart = payload => ({
  type: constants.FORGOT_PASSWORD_START,
  payload
});

export const forgotPasswordSuccess = payload => ({
  type: constants.FORGOT_PASSWORD_SUCCESS,
  payload
});

export const forgotPasswordFailure = payload => ({
  type: constants.FORGOT_PASSWORD_FAILURE,
  payload
});

// reset
export const resetPasswordStart = payload => ({
  type: constants.RESET_PASSWORD_START,
  payload
});

export const resetPasswordSuccess = payload => ({
  type: constants.RESET_PASSWORD_SUCCESS,
  payload
});

export const resetPasswordFailure = payload => ({
  type: constants.RESET_PASSWORD_FAILURE,
  payload
});

export const logout = () => ({
  type: constants.LOGOUT
});

// edit user
export const editUserStart = payload => ({
  type: constants.EDIT_USER_START,
  payload
});

export const editUserSuccess = payload => ({
  type: constants.EDIT_USER_SUCCESS,
  payload
});

export const editUserFailure = payload => ({
  type: constants.EDIT_USER_FAILURE,
  payload
});

export const cleanLogs = () => ({ type: constants.CLEAN_LOGS });

// get likes
export const getLikesStart = payload => ({
  type: constants.GET_LIKES_START,
  payload
});

export const getLikesSuccess = payload => ({
  type: constants.GET_LIKES_SUCCESS,
  payload
});

export const getLikesFailure = payload => ({
  type: constants.GET_LIKES_FAILURE,
  payload
});
