import api from '../../utils/api';

export const register = data => api(`/users/register`, 'POST', data);

export const login = data => api(`/users/login`, 'POST', data);

export const forgotPassword = email =>
  api(`/users/forgot-password`, 'POST', { email });

export const resetPassword = data => api(`/users/reset-password`, 'PUT', data);

export const editUser = data =>
  api(`/users`, 'PUT', { userId: data.userId, data });

export const getUserLikes = userId => api(`/favorites/${userId}`);
