export const breakpoints = {
  xxs: '375px',
  xs: '667px',
  sm: '768px',
  md: '959px',
  lg: '1024px',
  xl: '1200px',
  xxl: '1366px',
  vl: '1768px',
  vvl: '2500px',
};

export const breakpointsMin = {
  xxs: '376px',
  xs: '668px',
  sm: '769px',
  md: '960px',
  lg: '1025px',
  xl: '1201px',
  xxl: '1367px',
  vl: '1769px',
  vvl: '2501px',
};
