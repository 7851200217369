import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../containers/Auth/authActions';
// styles
import {
  Nav,
  NavSubcontainer,
  NavbarTop,
  NavbarBottom,
  Logo,
  NavbarList,
  NavbarListItem,
  NavbarListItemLink,
  NavbarBottomList,
  NavbarBottomListItem,
  NavbarBottomListItemLink,
} from './navbarStyles';
// components
import Login from '../../containers/Auth/Login';
import Register from '../../containers/Auth/Register';
import Newsletter from '../../containers/Newsletter/Newsletter';
import Contact from '../../containers/Contact/Contact';
import HowToPay from '../../containers/Home/HowToPay';
import Sends from '../../containers/Home/Sends';
import Refund from '../../containers/Home/Refund';
import WholeSale from '../../containers/Home/WholeSale';
// context
import { ModalContext } from '../Modal/Modal';

const NavbarLeft = () => {
  const { isAuth } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const history = useHistory();
  // MODAL
  const { setModalConfig } = useContext(ModalContext);

  const handleLogout = () => dispatch(logout());

  return (
    <Nav>
      <NavSubcontainer>
        <NavbarTop>
          <Logo onClick={() => history.push('/')} responsive />
          <NavbarList>
            <NavbarListItemLink to='/'>INICIO</NavbarListItemLink>
            <NavbarListItem onClick={() => setModalConfig({ component: <HowToPay /> })}>
              FORMAS DE PAGO
            </NavbarListItem>
            <NavbarListItem onClick={() => setModalConfig({ component: <Sends /> })}>
              ENVIOS
            </NavbarListItem>
            <NavbarListItem onClick={() => setModalConfig({ component: <Refund /> })}>
              DEVOLUCIONES
            </NavbarListItem>
            <NavbarListItem onClick={() => setModalConfig({ component: <WholeSale /> })}>
              POR MAYOR
            </NavbarListItem>
            <NavbarListItem onClick={() => setModalConfig({ component: <Contact /> })}>
              CONTACTO
            </NavbarListItem>
          </NavbarList>
        </NavbarTop>

        <NavbarBottom>
          <NavbarBottomList>
            <NavbarBottomListItem onClick={() => setModalConfig({ component: <Newsletter /> })}>
              Suscribirse
            </NavbarBottomListItem>
            <NavbarBottomListItemLink to='/search/order'>Buscar pedido</NavbarBottomListItemLink>

            {!isAuth ? (
              <>
                <NavbarBottomListItem onClick={() => setModalConfig({ component: <Login /> })}>
                  Iniciar Sesión
                </NavbarBottomListItem>
                <NavbarBottomListItem onClick={() => setModalConfig({ component: <Register /> })}>
                  Registrarse
                </NavbarBottomListItem>
              </>
            ) : (
              <>
                <NavbarBottomListItemLink to='/profile'>Mi perfil</NavbarBottomListItemLink>
                <NavbarBottomListItemLink to='/orders'>Mis compras</NavbarBottomListItemLink>
                <NavbarBottomListItem onClick={handleLogout}>Cerrar sesión</NavbarBottomListItem>
              </>
            )}
          </NavbarBottomList>
        </NavbarBottom>
      </NavSubcontainer>
    </Nav>
  );
};

export default NavbarLeft;
