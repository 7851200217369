import styled from 'styled-components';
import { breakpoints } from '../../assets/constants/breakpoints';

export const CartItemContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

export const ItemImageContainer = styled.div`
  width: 75px;
  height: 100px;
  background-color: var(--light-2);
`;

export const ItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ItemDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  justify-content: space-between;
  flex: 1;
  padding: 0.25rem 1rem;
`;

export const ItemDeleteIconContainer = styled.div`
  padding: 1rem;
  cursor: pointer;
  margin-left: auto;
`;

export const ItemName = styled.p`
  text-transform: uppercase;
  font-size: 1rem;
  color: var(--dark2);
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }

  @media screen and (max-width: ${breakpoints.xxl}) {
    font-size: 0.9rem;
  }
`;

export const ItemPrice = styled(ItemName)`
  margin: 0.5rem 0;
  font-weight: 600;
  :hover {
    text-decoration: none;
  }
`;

export const ItemQuantityContainer = styled.div`
  display: flex;
  background-color: var(--light);
`;

export const ItemQuantityButton = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0.45rem 0.8rem;
  font-size: 1.1rem;
  outline: 0;
`;

export const ItemQuantityInput = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.45rem;
  min-width: 40px;
  font-size: 0.9rem;
`;

export const ItemPriceThrough = styled.span`
  text-decoration: line-through;
  color: var(--gray);
  font-size: 0.85rem;
  margin-right: 0.5rem;
`;
