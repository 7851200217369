import * as constants from './combosConstants';

export const getCombosStart = payload => ({
  type: constants.GET_COMBOS_START,
  payload,
});

export const getCombosSuccess = payload => ({
  type: constants.GET_COMBOS_SUCCESS,
  payload,
});

export const getCombosFailure = payload => ({
  type: constants.GET_COMBOS_FAILURE,
  payload,
});

// Get product
export const getComboStart = payload => ({
  type: constants.GET_COMBO_START,
  payload,
});

export const getComboSuccess = payload => ({
  type: constants.GET_COMBO_SUCCESS,
  payload,
});

export const getComboFailure = payload => ({
  type: constants.GET_COMBO_FAILURE,
  payload,
});
