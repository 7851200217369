import styled from 'styled-components';
import { breakpoints } from '../../assets/constants/breakpoints';

export const IconScreenContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconScreenTitle = styled.p`
  font-size: 2.5rem;
  text-align: center;
  color: var(--black);
  margin: 1rem 0 2rem;

  @media screen and (max-width: ${breakpoints.md}) {
    font-size: 2rem;
  }
`;
