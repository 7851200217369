import { css } from 'styled-components';

export const colors = css`
  --white: #ffffff;
  --light: #fafafa;
  --light-2: #f2f2f2;
  --light-3: #eeeeee;
  --primary: #c99b6f;
  --red: #ff3333;
  --red-2: #b81e24;
  --green: #3f7b33;
  --blue: #3483fa;
  --gray: #9a9a9a;
  --gray-2: #5a5a5a;
  --dark: #333333;
  --dark-2: #252525;
  --dark-3: #1a1a1a;
  --black: #000000;
`;

export const colorsRGB = css`
  --white-rgb: 255, 255, 255;
  --light-rgb: 250, 250, 250;
  --light-2-rgb: 242, 242, 242;
  --light-3-rgb: 238, 238, 238;
  --primary-rgb: 201, 155, 111;
  --red-rgb: 255, 51, 51;
  --red-2-rgb: 184, 30, 36;
  --green-rgb: 63, 123, 51;
  --blue-rgb: 52, 131, 250;
  --gray-rgb: 154, 154, 154;
  --gray-2-rgb: 90, 90, 90;
  --dark-rgb: 51, 51, 51;
  --dark-2-rgb: 37, 37, 37;
  --dark-3-rgb: 26, 26, 26;
  --black-rgb: 0, 0, 0;
`;
