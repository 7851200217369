import { validateEmail, validatePassword } from '../../utils/validations';

export const validateLogin = ({ email, password }) => {
  const emailVal = validateEmail(email);
  const passwordVal = validatePassword(password);

  if (!emailVal.valid) return emailVal;
  if (!passwordVal.valid) return passwordVal;

  return { valid: true };
};

export const validateRegister = ({ email, password, plan }) => {
  const emailVal = validateEmail(email);
  const passwordVal = validatePassword(password, true);

  if (!emailVal.valid) return emailVal;
  if (!passwordVal.valid) return passwordVal;

  return { valid: true };
};

export const validateForgotPassword = email => {
  const emailVal = validateEmail(email);
  if (!emailVal.valid) return emailVal;
  return { valid: true };
};

export const validateResetPassword = ({ password }) => {
  const passwordVal = validatePassword(password, true);
  if (!passwordVal.valid) return passwordVal;

  return { valid: true };
};

// export const validateEditUser = ({
//   name,
//   surname,
//   dni,
//   postalCode,
//   phone,
//   oldPassword,
//   newPassword,
// }) => {
//   if (name) if (name.trim().length < 3) return { message: 'Nombre no válido' };

//   if (surname) {
//     if (surname.trim().length < 3) return { message: 'Apellido no válido' };
//   }

//   if (dni) {
//     const reg = /^\d{8}(?:[-\s]\d{4})?$/;

//     if (!reg.test(dni)) return { message: 'DNI no válido' };
//   }

//   if (postalCode) {
//     const reg = /^-?\d*\.?\d*$/;
//     if (!reg.test(postalCode)) return { message: 'Código postal no válido' };
//   }

//   if (phone) {
//     const reg = /^-?\d*\.?\d*$/;
//     if (!reg.test(phone)) return { message: 'Teléfono no válido' };
//   }

//   if (oldPassword || newPassword) {
//     if (oldPassword.trim().length || newPassword.trim().length) {
//       const oldPasswordVal = validatePassword(oldPassword, false, 'Antigua');
//       if (!oldPasswordVal.valid) return oldPasswordVal;

//       const newPasswordVal = validatePassword(newPassword, true, 'Nueva');
//       if (!newPasswordVal.valid) return newPasswordVal;
//     }
//   }

//   return { valid: true };
// };
