import React from 'react';
// components
import Button from '../Base/Button/Button';
// styles
import { EmptyContainer, EmptyText } from './emptyModalStyles';

const EmptyModal = ({ handleClick, title, Icon }) => {
  return (
    <EmptyContainer>
      <Icon height='140' width='140' fill='var(--dark)' />
      <EmptyText>{title}</EmptyText>
      <Button
        style={{ marginTop: '2rem' }}
        title='COMIENZA AHORA'
        onClick={handleClick}
      />
    </EmptyContainer>
  );
};

export default EmptyModal;
