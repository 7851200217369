import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import renderSnackbar from '../../utils/snackbar';

import * as constants from './authConstants';
import * as actions from './authActions';
import * as services from './authServices';
// context

function* register({ payload }) {
  try {
    const { success } = yield services.register(payload);
    if (success) {
      yield put(actions.registerSuccess());
      yield put(
        renderSnackbar(
          'success',
          'Registrado! Revisa tu email para activar la cuenta'
        )
      );
      yield payload.closeModal();
    }
  } catch (err) {
    yield put(actions.registerFailure({ error: err.message }));
  }
}

function* registerSaga() {
  yield takeLatest(constants.REGISTER_START, register);
}

// login
function* login({ payload }) {
  try {
    const { user, token } = yield services.login(payload);
    if (user && token) {
      localStorage.setItem('token', token);
      yield put(actions.loginSuccess({ user }));
      yield put(
        renderSnackbar(
          'success',
          'Bienvenido de nuevo a Mystiq, disfruta de nuestros productos!'
        )
      );
      yield payload.closeModal();
    }
  } catch (err) {
    yield put(actions.loginFailure({ error: err.message }));
  }
}

function* loginSaga() {
  yield takeLatest(constants.LOGIN_START, login);
}

// forgot password
function* forgotPassword({ payload }) {
  try {
    const { success } = yield services.forgotPassword(payload.email);
    if (success) {
      yield put(actions.forgotPasswordSuccess());
      yield put(renderSnackbar('success', 'Revisa tu email'));
      yield put(payload.closeModal());
    }
  } catch (err) {
    yield put(actions.forgotPasswordFailure({ error: err.message }));
  }
}

function* forgotPasswordSaga() {
  yield takeLatest(constants.FORGOT_PASSWORD_START, forgotPassword);
}

// reset password
function* resetPassword({ payload }) {
  try {
    const { success } = yield services.resetPassword(payload);
    if (success) {
      yield put(actions.resetPasswordSuccess());
      yield put(renderSnackbar('success', 'Contraseña guardada correctamente'));
      yield put(push('/'));
    }
  } catch (err) {
    yield put(actions.resetPasswordFailure({ error: err.message }));
  }
}

function* resetPasswordSaga() {
  yield takeLatest(constants.RESET_PASSWORD_START, resetPassword);
}

// edit user
function* editUser({ payload }) {
  try {
    const { user } = yield services.editUser(payload);
    if (user) {
      yield put(actions.editUserSuccess({ user }));
      yield put(renderSnackbar('success', 'Cambios guardados correctamente'));
    }
  } catch (err) {
    yield put(actions.editUserFailure({ error: err.message }));
  }
}

function* editUserSaga() {
  yield takeLatest(constants.EDIT_USER_START, editUser);
}

export default function* Saga() {
  yield all([
    fork(registerSaga),
    fork(loginSaga),
    fork(forgotPasswordSaga),
    fork(resetPasswordSaga),
    fork(editUserSaga)
  ]);
}
