import { all, fork, put, takeLatest } from 'redux-saga/effects';

import * as constants from './productsConstants';
import * as actions from './productsActions';
import * as services from './productsServices';

// Products
function* getProducts() {
  try {
    const { products } = yield services.getProducts();
    if (products) yield put(actions.getProductsSuccess({ list: products }));
  } catch (err) {
    yield put(actions.getProductsFailure({ error: err.message }));
  }
}

function* getProductsSaga() {
  yield takeLatest(constants.GET_PRODUCTS_START, getProducts);
}

// Product
function* getProduct({ payload }) {
  try {
    const { product } = yield services.getProduct(payload.slug);
    if (product) yield put(actions.getProductSuccess({ product }));
  } catch (err) {
    yield put(actions.getProductFailure({ error: err.message }));
  }
}

function* getProductSaga() {
  yield takeLatest(constants.GET_PRODUCT_START, getProduct);
}

export default function* productsSaga() {
  yield all([fork(getProductsSaga), fork(getProductSaga)]);
}
