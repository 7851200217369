import styled, { css } from 'styled-components';
import { breakpoints } from '../../assets/constants/breakpoints';
import Img from 'react-cool-img';

const ProductImageContainerSizes = {
  xl: 350,
  md: 300,
  sm: 250,
  xs: 200,
};

const ProductTitleSizes = {
  xl: 1.1,
  md: 1.1,
  sm: 0.95,
  xs: 0.95,
};
const ProductNewTagSizes = {
  xl: 12,
  md: 12,
  sm: 10,
  xs: 10,
};

const ProductNewTagPosition = {
  xl: 1,
  md: 1,
  sm: 0.5,
  xs: 0.5,
};

// product list
export const ProductListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(195px, 1fr));
  grid-gap: 2rem;
  margin-top: 2rem;

  @media screen and (max-width: ${breakpoints.sm}) {
    grid-gap: 1.25rem;
  }
  @media screen and (max-width: ${breakpoints.xs}) {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }

  @media screen and (max-width: ${breakpoints.xxs}) {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }

  @media screen and (max-width: 270px) {
    grid-template-columns: 1fr;
  }
`;

// product card

export const ProductCard = styled.div`
  height: 100%;
  align-self: stretch;
`;

export const ProductImageContainer = styled.div`
  background-color: var(--light);
  position: relative;
  overflow: hidden;

  ${({ size }) => css`
    height: ${ProductImageContainerSizes[size.size]}px;
    @media screen and (max-width: ${breakpoints.xxl}) {
      height: ${ProductImageContainerSizes[size.xxl]}px;
    }
    @media screen and (max-width: ${breakpoints.md}) {
      height: ${ProductImageContainerSizes[size.md]}px;
    }
    @media screen and (max-width: ${breakpoints.sm}) {
      height: ${ProductImageContainerSizes[size.sm]}px;
    }
  `}
`;

export const ProductImage = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 1;
  transition: opacity 300ms ease-in-out 0.05s, transform 0.3s ease-in-out;
`;

export const ProductSecondImage = styled(ProductImage)`
  opacity: 0;
  transform: scale3d(0.9, 0.9, 0.9);
  position: absolute;
`;

export const ProductCirclesContainer = styled.div`
  ${({ isMobile }) =>
    !isMobile
      ? css`
          position: absolute;
          transform: translateX(100%);
          opacity: 0;
          top: 0;
          right: 0;
          bottom: 0;
        `
      : css`
          display: flex;
          justify-content: center;
          border-top: 1px solid var(--light-2);
          margin-top: 0.5rem;
        `};

  transition: all 0.3s ease-in-out;
  z-index: 100;
`;

export const ProductCircleTag = styled.span`
  position: absolute;
  ${({ isMobile }) => (isMobile ? 'display: none' : null)};

  right: 160%;
  background-color: var(--primary);
  color: var(--white);
  opacity: 0;
  font-size: 0.7rem;
  letter-spacing: 0.5px;
  padding: 0.3rem 0.5rem;
  pointer-events: none;

  width: max-content;

  transition: all 0.3s ease-in-out;

  border-radius: 0.1rem;
  text-transform: uppercase;
  user-select: none;

  @media screen and (max-width: ${breakpoints.sm}) {
    max-width: 80px;
    line-height: 1.4;
  }
`;

export const ProductCircle = styled.div`
  ${({ isMobile }) =>
    isMobile
      ? css`
          position: relative;
          border: 1px solid var(--gray);
        `
      : css`
          box-shadow: 0 5px 6px rgba(0, 0, 0, 0.05);
        `};
  margin: 1rem;
  width: 2.8rem;
  height: 2.8rem;
  min-width: 2.8rem;
  min-height: 2.8rem;
  border-radius: 100%;
  background-color: ${({ active }) => (active ? 'var(--primary)' : 'var(--white)')};

  display: flex;
  align-items: center;
  justify-content: center;


  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.5;
    `}

  :hover ${ProductCircleTag} {
    opacity: 1;
    right: 95%;
  }
`;

export const ProductCardSubcontainer = styled.div`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;

  /* Efecto de la segunda imagen */

  /* :hover ${ProductImage} {
    transform: scale3d(0.9, 0.9, 0.9);
    opacity: 0;
  }

  :hover ${ProductSecondImage} {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }*/ 

  :hover ${ProductCirclesContainer} {
    opacity: 1;
    transform: translateX(0);
  } 
`;

export const ProductTitle = styled.h1`
  margin-top: 0.7rem;
  margin-bottom: 0;
  color: var(--gray2);
  font-weight: 300;

  ${({ size }) => css`
    font-size: ${ProductTitleSizes[size.size]}rem;
    @media screen and (max-width: ${breakpoints.xxl}) {
      font-size: ${ProductTitleSizes[size.xxl]}rem;
    }
    @media screen and (max-width: ${breakpoints.md}) {
      font-size: ${ProductTitleSizes[size.md]}rem;
    }
    @media screen and (max-width: ${breakpoints.sm}) {
      font-size: ${ProductTitleSizes[size.sm]}rem;
    }
  `}
`;

export const ProductPrice = styled(ProductTitle)`
  margin-top: 0.3rem;
  font-weight: 700;
  color: var(--black);
  margin-bottom: auto;
`;

export const ProductNewTag = styled.div`
  position: absolute;
  background-color: var(--black);
  padding: 4px 8px;
  color: var(--white);
  z-index: 10;

  ${({ size }) => css`
    top: ${ProductNewTagPosition[size.size]}rem;
    left: ${ProductNewTagPosition[size.size]}rem;
    font-size: ${ProductNewTagSizes[size.size]}px;
    @media screen and (max-width: ${breakpoints.xxl}) {
      font-size: ${ProductNewTagSizes[size.xxl]}px;
      top: ${ProductNewTagPosition[size.xxl]}rem;
      left: ${ProductNewTagPosition[size.xxl]}rem;
    }
    @media screen and (max-width: ${breakpoints.md}) {
      font-size: ${ProductNewTagSizes[size.md]}px;
      top: ${ProductNewTagPosition[size.md]}rem;
      left: ${ProductNewTagPosition[size.md]}rem;
    }
    @media screen and (max-width: ${breakpoints.sm}) {
      font-size: ${ProductNewTagSizes[size.sm]}px;
      top: ${ProductNewTagPosition[size.sm]}rem;
      left: ${ProductNewTagPosition[size.sm]}rem;
    }
  `}
`;

export const ProductOffer = styled(ProductNewTag)`
  transform: translateY(125%);
  background-color: var(--primary);
`;
