import React from 'react';
import { ModalTitleCenter } from '../../components/Modal/modalStyles';
import { ModalContainer, ModalMainText, ModalText } from './homeStyles';

const Refund = () => {
  return (
    <ModalContainer>
      <ModalTitleCenter>Devoluciones</ModalTitleCenter>
      <ModalMainText>
        Si la compra fue cancelada por nuestro sistema{' '}
        <strong>
          te devolveremos el dinero de forma automatica a tu cuenta de
          MercadoPago.
        </strong>
      </ModalMainText>
      <ModalText>
        Realizar CAMBIOS es muy sencillo,{' '}
        <strong>
          podés acercarte a alguno de nuestros locales con el producto en
          perfecto estado y la factura y/o ticket.
        </strong>{' '}
        Debe tenerse en cuenta que, al igual que las entregas, las devoluciones
        pueden demorar hasta 15 días hábiles.
      </ModalText>
    </ModalContainer>
  );
};

export default Refund;
