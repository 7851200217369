import * as constants from './sendsConstants';
    
export const getSendsStart = payload => ({
  type: constants.GET_SENDS_START,
  payload
});

export const getSendsSuccess = payload => ({
  type: constants.GET_SENDS_SUCCESS,
  payload
});

export const getSendsFailure = payload => ({
  type: constants.GET_SENDS_FAILURE,
  payload
});
