import * as constants from './cartConstants';
import setOffer from '../../utils/setOffer';

const cartWithoutProduct = (cart, productId) => cart.filter(product => product._id !== productId);

const isProductInCart = (cart, product) =>
  cart.findIndex(cartProduct => cartProduct._id === product._id);

const addToCart = (cart, product, actualPrice) => {
  const productIndex = isProductInCart(cart, product);
  let newCart = [...cart];

  if (productIndex !== -1) newCart[productIndex].quantity += 1;
  else newCart = [...newCart, { ...product, quantity: 1 }];

  return {
    list: [...newCart],
    price:
      actualPrice +
      setOffer(product.price, {
        type: product?.offer?.type,
        amount: product?.offer?.amount,
      }),
  };
};

const removeFromCart = (cart, product, actualPrice) => ({
  list: [...cartWithoutProduct(cart, product._id)],
  price:
    actualPrice -
    setOffer(product.price, {
      type: product?.offer?.type,
      amount: product?.offer?.amount,
    }) *
      product.quantity,
});

const removeProductItemFromCart = (cart, product, actualPrice) => {
  const productIndex = isProductInCart(cart, product);
  let newCart = [...cart];

  if (product.quantity === 1) newCart = [...cartWithoutProduct(cart, product._id)];
  else newCart[productIndex].quantity -= 1;

  return {
    list: [...newCart],
    price:
      actualPrice -
      setOffer(product.price, {
        type: product?.offer?.type,
        amount: product?.offer?.amount,
      }),
  };
};

const initialState = {
  list: [],
  price: 0,
  send: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.ADD_PRODUCT:
      return addToCart(state.list, payload.product, state.price);

    case constants.REMOVE_PRODUCT:
      return removeFromCart(state.list, payload.product, state.price);

    case constants.REMOVE_PRODUCT_ITEM:
      return removeProductItemFromCart(state.list, payload.product, state.price);

    case constants.SEND_MODE:
      return { ...state, send: payload.amount };

    case constants.CLEAR_CART:
      return { ...state, list: [], price: 0, send: 0 };

    default:
      return state;
  }
};
