import * as constants from './cartConstants';

export const addProductToCart = payload => ({
  type: constants.ADD_PRODUCT,
  payload
});

export const removeProductFromCart = payload => ({
  type: constants.REMOVE_PRODUCT,
  payload
});

export const removeProductItemFromCart = payload => ({
  type: constants.REMOVE_PRODUCT_ITEM,
  payload
});

export const sendMode = payload => ({
  type: constants.SEND_MODE,
  payload
});

export const clearCart = () => ({
  type: constants.CLEAR_CART
});
