import * as constants from './productsConstants';

// Get products
export const getProductsStart = payload => ({
  type: constants.GET_PRODUCTS_START,
  payload,
});

export const getProductsSuccess = payload => ({
  type: constants.GET_PRODUCTS_SUCCESS,
  payload,
});

export const getProductsFailure = payload => ({
  type: constants.GET_PRODUCTS_FAILURE,
  payload,
});

// Get product
export const getProductStart = payload => ({
  type: constants.GET_PRODUCT_START,
  payload,
});

export const getProductSuccess = payload => ({
  type: constants.GET_PRODUCT_SUCCESS,
  payload,
});

export const getProductFailure = payload => ({
  type: constants.GET_PRODUCT_FAILURE,
  payload,
});
