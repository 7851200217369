import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { getComboStart } from './combosActions';
// components
import LoaderPage from '../../components/Base/Loader/LoaderPage';
import IconScreen from '../../components/Icon/IconScreen';
import ProductHeader from '../../components/Product/ProductHeader';
import ProductCategories from '../../components/Product/ProductCategories';
import ProductItem from '../../components/ProductItem/ProductItem';
// styles
import { ComboContainer } from './combosStyles';
// icons
import { ReactComponent as ProductNotFound } from '../../assets/icons/404-product.svg';
import {
  RecommendedProductsContainer,
  RecommendedProductsTitle,
  RecommendedProductsSubcontainer,
} from '../Products/productsStyles';

const Combo = () => {
  const dispatch = useDispatch();

  const { combo, loading, error } = useSelector(({ combos }) => combos);

  const { slug } = useParams();

  useEffect(() => {
    dispatch(getComboStart({ slug }));
  }, [dispatch, slug]);

  if (loading) return <LoaderPage />;

  return (
    <ComboContainer initial={{ y: '100vh' }} animate={{ y: 0 }} transition={{ duration: 0.4 }}>
      <Helmet>
        <title>Mystiq - {combo.name ?? 'Pack no encontrado'}</title>
      </Helmet>
      {error ? (
        <IconScreen Icon={ProductNotFound} title='¡Pack no encontrado!' />
      ) : (
        <>
          <ProductHeader product={combo} />
          <ProductCategories product={combo} />
          <RecommendedProductsContainer>
            <RecommendedProductsTitle>Productos relacionados</RecommendedProductsTitle>
            <RecommendedProductsSubcontainer>
              {combo?.products?.map(product => (
                <ProductItem
                  key={product._id}
                  size={{ default: 'xs' }}
                  options={{ hasOptions: false, tags: false }}
                  {...product}
                />
              ))}
            </RecommendedProductsSubcontainer>
          </RecommendedProductsContainer>
        </>
      )}
    </ComboContainer>
  );
};

export default Combo;
