import React, { useContext, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
// styles
import {
  ProductCard,
  ProductCardSubcontainer,
  ProductImage,
  ProductTitle,
  ProductPrice,
  ProductImageContainer,
  // ProductSecondImage,
  ProductNewTag,
  ProductOffer
} from './productItemStyles';
import { ItemPriceThrough } from '../Cart/cartStyles';

// modalsContext
import { ModalContext } from '../Modal/Modal';
// utils
import setOffer from '../../utils/setOffer';
import { sizes } from '../../utils/sizes';
import ProductItemCircles from './ProductItemCircles';

const ProductItem = ({
  _id,
  name,
  price,
  slug,
  images,
  offer,
  type,
  stock,
  options = {},
  size = {} // keys: default, xxl, md, sm /// values: xs, sm, md, xl
}) => {
  const history = useHistory();

  // modal
  const { closeModal } = useContext(ModalContext);

  // options
  const opts = useMemo(
    () => ({
      hasOptions: true,
      fav: true,
      cart: true,
      tags: true, // Like y Carrito
      labels: true, // Nuevo y Oferta
      ...options
    }),
    [options]
  );

  const productSizes = useMemo(() => sizes(size), [size]);

  const goToProductPage = useCallback(() => {
    closeModal();
    type === 'Combo'
      ? history.push(`/pack/${slug}`)
      : history.push(`/product/${slug}`);
  }, [closeModal, history, slug, type]);

  const outputPrice = useMemo(
    () => (offer, price) => {
      if (offer?._id && offer?.status === 'confirmed') {
        return (
          <>
            <ItemPriceThrough>${price}</ItemPriceThrough>$
            {setOffer(price, { type: offer.type, amount: offer.amount })}
          </>
        );
      } else return '$' + price;
    },
    []
  );

  return (
    <ProductCard>
      <ProductCardSubcontainer onClick={goToProductPage}>
        <ProductImageContainer size={productSizes}>
          {opts.labels && (
            <>
              <ProductNewTag size={productSizes}>NUEVO</ProductNewTag>
              {offer?._id && (
                <ProductOffer size={productSizes}>
                  {' '}
                  {offer.type === 'fixed' && '- $'}
                  {offer.amount}
                  {offer.type === 'porcentage' && '% OFF'}
                </ProductOffer>
              )}
            </>
          )}

          {/* <ProductSecondImage src={images?.length > 1 ? images[1] : ''} /> */}
          <ProductImage src={images?.length && images[0]} z />
        </ProductImageContainer>

        <ProductTitle size={productSizes}>{name}</ProductTitle>
        <ProductPrice size={productSizes}>
          {outputPrice(offer, price)}
        </ProductPrice>

        {opts.hasOptions ? (
          <ProductItemCircles
            opts={opts}
            product={{
              _id,
              name,
              slug,
              price,
              offer,
              images,
              type,
              stock
            }}
          />
        ) : null}
      </ProductCardSubcontainer>
    </ProductCard>
  );
};

export default React.memo(ProductItem);
