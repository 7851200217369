import React, { useEffect, useContext } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { registerStart, cleanLogs } from './authActions';
import { validateRegister } from './authValidations';
// hooks
import { useForm } from 'react-hook-form';
import useAuth from '../../hooks/useAuth';
// components
import Login from './Login';
import Input from '../../components/Base/Input/Input';
import Button from '../../components/Base/Button/Button';
// styles
import {
  AuthTextsContainer,
  AuthText,
  AuthTextLink,
  AuthTextContainer
} from './authStyles';
import {
  FormModalContainer,
  FormModalLabel,
  ModalTitleCenter,
  FormModalSubContainer
} from '../../components/Modal/modalStyles';
// context
import { ModalContext } from '../../components/Modal/Modal';

const Register = () => {
  useAuth();

  const dispatch = useDispatch();
  const { register, handleSubmit, setError, errors } = useForm();

  const { error, loading } = useSelector(({ auth }) => auth);

  const { setModalConfig, closeModal } = useContext(ModalContext);

  const onSubmit = data => {
    if (!loading) {
      const isValid = validateRegister({ ...data });
      if (isValid.valid) {
        dispatch(registerStart({ ...data, closeModal }));
      } else setError('error', '', isValid.message);
    }
  };

  useEffect(() => {
    return () => dispatch(cleanLogs());
  }, [dispatch]);

  const handleChangeModal = component =>
    !loading && setModalConfig({ component });

  return (
    <>
      <ModalTitleCenter>Registrarse</ModalTitleCenter>
      <FormModalContainer onSubmit={handleSubmit(onSubmit)}>
        <FormModalSubContainer disabled={loading}>
          <FormModalLabel>
            <Input
              ref={register}
              type='text'
              placeholder='Correo electrónico'
              name='email'
            />
          </FormModalLabel>

          <FormModalLabel>
            <Input
              ref={register}
              type='password'
              placeholder='Contraseña'
              name='password'
              error={error || errors.error?.message}
            />
          </FormModalLabel>

          <AuthTextsContainer>
            <AuthTextContainer>
              <AuthText>¿Ya formas parte?</AuthText>
              <AuthTextLink onClick={() => handleChangeModal(<Login />)}>
                Iniciar Sesión
              </AuthTextLink>
            </AuthTextContainer>
          </AuthTextsContainer>

          <Button title='Registrarme' isLoading={loading} />
        </FormModalSubContainer>
      </FormModalContainer>
    </>
  );
};

export default Register;
