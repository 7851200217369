import { all, fork, put, takeLatest } from 'redux-saga/effects';

import * as constants from './sendsConstants';
import * as actions from './sendsActions';
import * as services from './sendsServices';

function* getSends() {
  try {
    const { sends } = yield services.getSends();
    if (sends) yield put(actions.getSendsSuccess({ list: sends }));
  } catch (err) {
    yield put(actions.getSendsFailure({ error: err.message }));
  }
}

function* getSendsSaga() {
  yield takeLatest(constants.GET_SENDS_START, getSends);
}

export default function* sendsSaga() {
  yield all([fork(getSendsSaga)]);
}
