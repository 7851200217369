import React from 'react';
import { LoaderBallsContainer } from './loaderStyles';

const LoaderBalls = ({ color = 'var(--black)', size = 6 }) => {
  return (
    <LoaderBallsContainer color={color} size={size}></LoaderBallsContainer>
  );
};

export default LoaderBalls;
