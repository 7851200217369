import { all, fork, put, takeLatest } from 'redux-saga/effects';

import * as constants from './favoritesConstants';
import * as actions from './favoritesActions';
import * as services from './favoritesServices';

function* getUserFavorites({ payload }) {
  try {
    const { likes } = yield services.getUserFavorites(payload);
    if (likes) yield put(actions.getUserFavoritesSuccess({ list: likes }));
  } catch (err) {
    yield put(actions.getUserFavoritesFailure({ error: err.message }));
  }
}

function* getUserFavoritesSaga() {
  yield takeLatest(constants.GET_USER_FAVORITES_START, getUserFavorites);
}

function* likeProduct({ payload: { userId, productId, action } }) {
  try {
    const { like, success } = yield services.likeProduct(userId, productId, action);
    if (success && (action !== 'like' || like))
      yield put(actions.likeProductSuccess({ like, productId, action }));
  } catch (err) {
    yield put(actions.likeProductFailure({ error: err.message }));
  }
}

function* likeProductSaga() {
  yield takeLatest(constants.LIKE_PRODUCT_START, likeProduct);
}

export default function* favoritesSaga() {
  yield all([fork(getUserFavoritesSaga), fork(likeProductSaga)]);
}
