import styled from 'styled-components';

export const SearchModalContainer = styled.div``;

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  :hover img {
    transform: scale(1.1);
  }
`;

export const ProductImageContainer = styled.div`
  width: 100%;
  height: 200px;
  background-color: var(--light);
  padding: 1rem;
`;

export const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all 0.2s ease;
`;

export const ProductName = styled.h1`
  font-size: 1rem;
  margin-top: 1rem;
  font-weight: 500;
`;

export const SearchResultText = styled.p`
  margin: 2rem 0;
  text-align: center;
  word-wrap: break-word;
`;
