import styled, { keyframes } from 'styled-components';

/* ================================== LOADER CIRCLE ================================== */
const LoaderCircleContainerAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const LoaderCircleContainer = styled.svg`
  animation: ${LoaderCircleContainerAnimation} 2s linear infinite;
  height: ${({ size }) => size || 25}px;
  width: ${({ size }) => size || 25}px;
`;

const LoaderCircleComponentAnimation = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
`;

export const LoaderCircleComponent = styled.circle`
  animation: ${LoaderCircleComponentAnimation} 2s ease-in-out infinite;
  stroke-linecap: round;
`;

/* ================================== LOADER 3 BALS ================================== */

const LoaderBallsAnimation = keyframes`
	0% { opacity: 1; }
	33% {  opacity: 0.25; }
	66% { opacity: 0.25; }
	100% {  opacity: 1; }
`;
const LoaderBallsAnimationBefore = keyframes`
	0% { opacity: 0.25; }
	33% { opacity: 1; }
	66% { opacity: 0.25; }
`;
const LoaderBallsAnimationAfter = keyframes`
	33% { opacity: 0.25; }
	66% { opacity: 1; }
	100% { opacity: 0.25; }
`;

export const LoaderBallsContainer = styled.div`
  position: relative;
  opacity: 1;
  animation: ${LoaderBallsAnimation} 1s infinite linear;

  &,
  &:before,
  &:after {
    border-radius: 50%;
    background-color: ${({ color }) => color};
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    transform-origin: center center;
    display: inline-block;
  }

  :before,
  :after {
    content: '';
    position: absolute;
    opacity: 0.25;
    top: 50%;
    transform: translateY(-50%);
  }

  :before {
    left: ${({ size }) => size * 2}px;
    animation: ${LoaderBallsAnimationBefore} 1s infinite linear;
  }

  :after {
    left: -${({ size }) => size * 2}px;
    animation: ${LoaderBallsAnimationAfter} 1s infinite linear;
  }
`;

/* ================================== LOADER PAGE ================================== */

export const LoaderPageContainer = styled.div`
  min-height: inherit;
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 15rem;
`;

export const LoaderPageTitle = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  padding: 1rem;
`;
