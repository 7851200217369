import { all, fork } from 'redux-saga/effects';

// sagas
import authSaga from './containers/Auth/authSaga';
import newsletterSaga from './containers/Newsletter/newsletterSaga';
import contactSaga from './containers/Contact/contactSaga';
import productsSaga from './containers/Products/productsSaga';
import checkoutSaga from './containers/Checkout/checkoutSaga';
import ordersSaga from './containers/Orders/ordersSaga';
import favoritesSaga from './containers/Favorites/favoritesSaga';
import combosSaga from './containers/Combos/combosSaga';
import sendsSaga from './containers/Sends/sendsSaga';

export default function* root() {
  yield all([
    fork(authSaga),
    fork(newsletterSaga),
    fork(checkoutSaga),
    fork(contactSaga),
    fork(productsSaga),
    fork(ordersSaga),
    fork(combosSaga),
    fork(favoritesSaga),
    fork(sendsSaga)
  ]);
}
