import React from 'react';
// styles
import { FullContainer } from './navbarStyles';
import NavbarLeft from './NavbarLeft';
import NavbarRight from './NavbarRight';

const Navbar = ({ children }) => {
  return (
    <FullContainer>
      <NavbarLeft />
      {children}
      <NavbarRight />
    </FullContainer>
  );
};

export default React.memo(Navbar);
