import React, { useEffect, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { getUserFavoritesStart } from './favoritesActions';
// components
import LoaderPage from '../../components/Base/Loader/LoaderPage';
import ProductItem from '../../components/ProductItem/ProductItem';
import EmptyModal from '../../components/EmptyModal/EmptyModal';
// styles
import { Container } from './favoritesStyles';
import { ModalTitle } from '../../components/Modal/modalStyles';
import { ProductListContainer } from '../../components/ProductItem/productItemStyles';
// modalsContext
import { ModalContext } from '../../components/Modal/Modal';
// icons
import { ReactComponent as HeartBroke } from '../../assets/icons/heart-broken.svg';

const Favorites = () => {
  const dispatch = useDispatch();
  const { list, loading, error } = useSelector(({ favorites }) => favorites);
  const {
    user: { _id: user },
    isAuth: auth
  } = useSelector(({ auth }) => auth);

  const history = useHistory();
  const { closeModal } = useContext(ModalContext);

  useEffect(() => {
    !list.length && auth && dispatch(getUserFavoritesStart(user));
  }, [dispatch, user, auth, list.length]);

  const handleEmptyCartClick = useCallback(() => {
    closeModal();
    return history.location.pathname !== '/' ? history.push('/') : null;
  }, [history, closeModal]);

  if (!list?.length && loading)
    return <LoaderPage title='Cargando favoritos' />;
  if (error) return <h1>{error}</h1>;

  return (
    <Container>
      <ModalTitle>Mis Favoritos</ModalTitle>
      {list?.length ? (
        <ProductListContainer>
          {list.map(({ product }) => (
            <ProductItem
              key={product?._id}
              size={{ default: 'xs' }}
              options={{ cart: false, tags: false }}
              {...product}
            />
          ))}
        </ProductListContainer>
      ) : (
        <EmptyModal
          Icon={HeartBroke}
          title='¡Aún no tienes favoritos!'
          handleClick={handleEmptyCartClick}
        />
      )}
    </Container>
  );
};

export default Favorites;
