import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

function useAuth() {
  const [auth, setAuth] = useState(false);
  const { isAuth, user, loading } = useSelector(({ auth }) => auth);

  const history = useHistory();

  useEffect(() => {
    setAuth(
      isAuth &&
        Object.keys(user).length &&
        localStorage.getItem('token') &&
        !loading
    );
  }, [isAuth, loading, user]);

  useEffect(() => {
    if (auth) history.push('/');
  }, [auth, history]);

  return { auth };
}

export default useAuth;
