import React from 'react';
// components
import Btn from '../Base/Button/Button';
// styles
import { IconScreenContainer, IconScreenTitle } from './iconStyles';

const IconScreen = ({ Icon, title, buttonTitle, onClickButton }) => {
  return (
    <IconScreenContainer>
      {Icon ? <Icon height='150' width='150' fill='var(--black)' /> : null}
      <IconScreenTitle>{title}</IconScreenTitle>
      {buttonTitle ? <Btn title={buttonTitle} onClick={onClickButton} /> : null}
    </IconScreenContainer>
  );
};

export default React.memo(IconScreen);
