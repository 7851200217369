import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App/App';
import * as serviceWorker from './serviceWorker';
import { SocketIOProvider } from 'use-socketio';
import moment from 'moment';
import 'moment/locale/es';
import ReactGA from 'react-ga';
// redux config
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore, { history, sagaMiddleware } from './store';
import sagas from './sagas';
import { SnackbarProvider } from 'notistack';
const { persistor, store } = configureStore();
sagaMiddleware.run(sagas);
moment.locale('es');

console.log('envs', process.env);

ReactGA.initialize('UA-158646060-1');

history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

ReactDOM.render(
  <SocketIOProvider url={process.env.REACT_APP_SOCKET_URL}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <SnackbarProvider maxSnack={1}>
            <App />
          </SnackbarProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </SocketIOProvider>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
