import React from 'react';
import LoaderBalls from './LoaderBalls';
import LoaderCircle from './LoaderCircle';

export const Loader = ({ size, color, stroke, type }) => {
  return type === 'balls' ? (
    <LoaderBalls size={size} color={color} />
  ) : (
    <LoaderCircle size={size} color={color} stroke={stroke} />
  );
};
