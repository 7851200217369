import { enqueueSnackbar } from '../containers/Notifier/notifierActions';

export default (variant = 'error', message) =>
  enqueueSnackbar({
    message,
    options: {
      variant,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
      }
    }
  });
