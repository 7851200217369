export const validateEmail = email => {
  if (!email.trim().length) return { message: 'Correo electrónico requerido' };

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.trim()))
    return { message: 'Correo electrónico inválido' };

  return { valid: true };
};

export const validatePassword = (password, strong, passwordName = '') => {
  const initialMessage = `La ${passwordName} contraseña debe tener al menos`;

  if (!password.trim().length)
    return { message: `${passwordName} Contraseña requerida` };

  if (strong) {
    if (password.trim().length >= 6 && password.trim().length <= 30) {
      if (!password.match(/[A-Z]/g))
        return { message: `${initialMessage} una mayúscula` };

      if (!password.match(/[0-9]/g))
        return { message: `${initialMessage} un número` };
    } else {
      return { message: `${initialMessage} 6 caracteres` };
    }
  }

  return { valid: true };
};
