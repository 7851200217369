import api from '../../utils/api';

export const getUserFavorites = userId => api(`/favorites/${userId}`);

export const likeProduct = (userId, productId, action) =>
  api('/favorites', action === 'like' ? 'POST' : 'DELETE', {
    productId,
    userId
  });

export const isProductLiked = (userId, productId) =>
  api(`/favorites?userId=${userId}&&productId=${productId}`);
