import * as constants from './ordersConstants';

const initialState = {
  list: [],
  order: {},
  loading: true,
  reqLoading: false,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.GET_ORDERS_START:
    case constants.GET_ORDER_START:
      return {
        ...state,
        order: {},
        loading: true,
        reqLoading: false,
        error: null,
      };
    case constants.SEARCH_ORDER_START:
      return { ...state, reqLoading: true };

    case constants.GET_ORDERS_SUCCESS:
      return {
        ...state,
        list: payload.list,
        loading: false,
        reqLoading: false,
        error: null,
      };

    case constants.GET_ORDER_SUCCESS:
      return {
        ...state,
        order: payload.order,
        reqLoading: false,
        loading: false,
        error: null,
      };

    case constants.GET_ORDERS_FAILURE:
    case constants.GET_ORDER_FAILURE:
    case constants.SEARCH_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        reqLoading: false,
        error: payload.error,
      };

    default:
      return state;
  }
};
