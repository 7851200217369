import React from 'react';
// packages
import ReactImageMagnify from 'react-image-magnify';
// Styles
import { ProductImagesContainer, ProductImage } from './productStyles';
import { useState } from 'react';

const ProductHeaderImage = ({ name, images }) => {
  const [selectedImage, setSelectedImage] = useState(images ? images[0] : '');

  return (
    <ProductImagesContainer>
      <ReactImageMagnify
        {...{
          smallImage: {
            alt: name,
            isFluidWidth: true,
            src: selectedImage,
          },
          largeImage: {
            src: selectedImage,
            width: 1200, // zoom
            height: 1200, // zoom
          },
          enlargedImagePosition: 'over',
        }}
      />

      {images?.map((e, i) => (
        <ProductImage
          key={i}
          src={e}
          onClick={() => setSelectedImage(e)}
          selected={e === selectedImage}
        />
      ))}
    </ProductImagesContainer>
  );
};

export default React.memo(ProductHeaderImage);
