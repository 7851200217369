import * as constants from './checkoutConstants';

const initialState = {
  loading: false,
  error: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.SEND_ORDER_START:
      return { ...state, loading: true, error: null };
    case constants.SEND_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case constants.SEND_ORDER_FAILURE:
      return { ...state, loading: false, error: payload.error };

    default:
      return state;
  }
};
