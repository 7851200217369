import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import renderSnackbar from '../../utils/snackbar';

import * as constants from './contactConstants';
import * as actions from './contactActions';
import * as services from './contactServices';

function* sendContact({ payload }) {
  try {
    const { success } = yield services.sendEmail(payload);
    if (success) {
      yield put(actions.sendContactSuccess());
      yield put(renderSnackbar('success', 'Enviado correctamente!'));
      yield put(push('/'));
    }
  } catch (err) {
    yield put(actions.sendContactFailure({ error: err.message }));
  }
}

function* sendContactSaga() {
  yield takeLatest(constants.SEND_CONTACT_START, sendContact);
}

export default function* contactSaga() {
  yield all([fork(sendContactSaga)]);
}
