import * as constants from './favoritesConstants';

export const getUserFavoritesStart = payload => ({
  type: constants.GET_USER_FAVORITES_START,
  payload,
});

export const getUserFavoritesSuccess = payload => ({
  type: constants.GET_USER_FAVORITES_SUCCESS,
  payload,
});

export const getUserFavoritesFailure = payload => ({
  type: constants.GET_USER_FAVORITES_FAILURE,
  payload,
});

export const likeProductStart = payload => ({
  type: constants.LIKE_PRODUCT_START,
  payload,
});

export const likeProductSuccess = payload => ({
  type: constants.LIKE_PRODUCT_SUCCESS,
  payload,
});

export const likeProductFailure = payload => ({
  type: constants.LIKE_PRODUCT_FAILURE,
  payload,
});
