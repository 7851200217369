import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getProductStart } from './productsActions';
// components
import LoaderPage from '../../components/Base/Loader/LoaderPage';
// modalsContext
import {
  ProductContainer,
  RecommendedProductsContainer,
  RecommendedProductsTitle,
  RecommendedProductsSubcontainer,
} from './productsStyles';
import IconScreen from '../../components/Icon/IconScreen';
import { ReactComponent as ProductNotFound } from '../../assets/icons/404-product.svg';
import ProductHeader from '../../components/Product/ProductHeader';
import ProductCategories from '../../components/Product/ProductCategories';
import ProductItem from '../../components/ProductItem/ProductItem';

const Product = () => {
  const dispatch = useDispatch();

  const { list, product, loading, error } = useSelector(({ products }) => products);

  const { slug } = useParams();

  const tags = React.useMemo(() => product?.tags?.map(t => t.name), [product]);

  useEffect(() => {
    dispatch(getProductStart({ slug }));
  }, [dispatch, slug]);

  if (loading) return <LoaderPage />;

  return (
    <ProductContainer
      initial={{ y: '100vh', opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5, type: 'spring', damping: 20 }}
    >
      <Helmet>
        <title>Mystiq - {product.name ?? 'Producto no encontrado'}</title>
      </Helmet>
      {error ? (
        <IconScreen Icon={ProductNotFound} title='¡Producto no encontrado!' />
      ) : (
        <>
          <ProductHeader product={product} />
          <ProductCategories product={product} />

          <RecommendedProductsContainer>
            <RecommendedProductsTitle>Productos relacionados</RecommendedProductsTitle>
            <RecommendedProductsSubcontainer>
              {list?.map(p => {
                if (p?.tags?.filter(t => tags?.includes(t?.name)).length && p._id !== product._id)
                  return (
                    <ProductItem
                      key={p._id}
                      size={{ default: 'xs' }}
                      options={{
                        hasOptions: false,
                        tags: false,
                        labels: false,
                      }}
                      {...p}
                    />
                  );
              })}
            </RecommendedProductsSubcontainer>
          </RecommendedProductsContainer>
        </>
      )}
    </ProductContainer>
  );
};

export default Product;
