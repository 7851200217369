import React, { useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { clearCart } from './cartActions';
// components
import Button from '../../components/Base/Button/Button';
import CartItem from '../../components/Cart/CartItem';
import EmptyModal from '../../components/EmptyModal/EmptyModal';
// styles
import { ModalTitle } from '../../components/Modal/modalStyles';
import {
  Container,
  CartList,
  CartFooterContainer,
  TotalTitle,
  TotalText,
  FooterText,
  ButtonsContainer
} from './cartStyles';
// modalsContext
import { ModalContext } from '../../components/Modal/Modal';
// Icons
import { ReactComponent as ShoppingAdd } from '../../assets/icons/shopping-bag.svg';

const Cart = () => {
  const dispatch = useDispatch();
  const { list, price, send } = useSelector(({ cart }) => cart);

  const history = useHistory();

  const { closeModal } = useContext(ModalContext);

  const goToProductPage = useCallback(
    slug => {
      closeModal();
      history.push(`/product/${slug}`);
    },
    [closeModal, history]
  );

  const handleEmptyCartClick = useCallback(() => {
    closeModal();
    return history.location.pathname !== '/' ? history.push('/') : null;
  }, [history, closeModal]);

  return (
    <Container>
      <ModalTitle>Mi carrito</ModalTitle>

      {list.length ? (
        <>
          <CartList>
            {list.map(product => (
              <CartItem
                key={product._id}
                product={product}
                goToProductPage={() => goToProductPage(product.slug)}
              />
            ))}
          </CartList>

          <CartFooterContainer>
            <TotalTitle>{!send ? 'Subtotal' : 'Total'}</TotalTitle>
            <TotalText>${price + (send ?? 0)}</TotalText>
            <FooterText>
              Gastos de envío e impuestos calculados al finalizar la compra.
            </FooterText>
            <ButtonsContainer>
              <Button
                title='REMOVER TODOS'
                size={{ xxl: 'xl' }}
                onClick={() => dispatch(clearCart())}
              />

              <Button
                title='FINALIZAR COMPRA'
                size={{ xxl: 'xl' }}
                onClick={() => {
                  closeModal();
                  history.push('/checkout');
                }}
              />
            </ButtonsContainer>
          </CartFooterContainer>
        </>
      ) : (
        <EmptyModal
          Icon={ShoppingAdd}
          title='¡Aún no tienes productos!'
          handleClick={handleEmptyCartClick}
        />
      )}
    </Container>
  );
};

export default Cart;
