import styled from 'styled-components';
import { breakpoints } from '../../assets/constants/breakpoints';

export const Main = styled.main`
  flex: 1;
  padding: 0 2rem;

  @media screen and (max-width: ${breakpoints.xl}) {
    padding: 0;
    grid-area: main;
    order: 1;
  }
`;
