export const GET_USER_FAVORITES_START = 'app/Favorites/GET_USER_FAVORITES_START';
export const GET_USER_FAVORITES_SUCCESS = 'app/Favorites/GET_USER_FAVORITES_SUCCESS';
export const GET_USER_FAVORITES_FAILURE = 'app/Favorites/GET_USER_FAVORITES_FAILURE';

export const LIKE_PRODUCT_START = 'app/Products/LIKE_PRODUCT_START';
export const LIKE_PRODUCT_SUCCESS = 'app/Products/LIKE_PRODUCT_SUCCESS';
export const LIKE_PRODUCT_FAILURE = 'app/Products/LIKE_PRODUCT_FAILURE';

export const IS_PRODUCT_LIKED_START = 'app/Products/IS_PRODUCT_LIKED_START';
export const IS_PRODUCT_LIKED_SUCCESS = 'app/Products/IS_PRODUCT_LIKED_SUCCESS';
export const IS_PRODUCT_LIKED_FAILURE = 'app/Products/IS_PRODUCT_LIKED_FAILURE';
