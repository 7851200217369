import { all, fork, put, takeLatest } from 'redux-saga/effects';
import renderSnackbar from '../../utils/snackbar';

import * as constants from './ordersConstants';
import * as actions from './ordersActions';
import * as services from './ordersServices';
import { push } from 'connected-react-router';

function* getOrders({ payload: { userId } }) {
  try {
    const { orders } = yield services.getOrders(userId);
    if (orders) yield put(actions.getOrdersSuccess({ list: orders }));
  } catch (err) {
    yield put(actions.getOrdersFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* getOrdersSaga() {
  yield takeLatest(constants.GET_ORDERS_START, getOrders);
}

// get order
function* getOrder({ payload: { orderId } }) {
  try {
    const { order } = yield services.getOrder(orderId);
    if (order) yield put(actions.getOrderSuccess({ order }));
  } catch (err) {
    yield put(actions.getOrdersFailure({ error: err.message }));
    yield put(renderSnackbar('error', err.message));
  }
}

function* getOrderSaga() {
  yield takeLatest(constants.GET_ORDER_START, getOrder);
}

// Search

function* searchOrder({ payload }) {
  try {
    const { order } = yield services.searchOrder(payload);
    if (order) yield put(push(`/order/${order._id}`));
  } catch (err) {
    yield put(actions.searchOrderFailure({ error: err.message }));
  }
}

function* searchOrderSaga() {
  yield takeLatest(constants.SEARCH_ORDER_START, searchOrder);
}

export default function* ordersSaga() {
  yield all([fork(getOrdersSaga), fork(getOrderSaga), fork(searchOrderSaga)]);
}
