import React, { useState } from 'react';
// styles
import {
  InputContainer,
  InputField,
  InputPlaceholder,
  InputRightIconContainer,
  InputFieldTextarea,
  InputMaxLength
} from './inputStyles';
import Error from './Error';
// icons
import { ReactComponent as Eye } from '../../../assets/icons/eye.svg';
import { ReactComponent as EyeOff } from '../../../assets/icons/eye-off.svg';

const Input = React.forwardRef(
  (
    {
      placeholder,
      optional,
      IconRight,
      type = 'text',
      name,
      error = '',
      success = '',
      disabled,
      defaultValue,
      onChange,
      maxLength,
      length,
      style
    },
    ref
  ) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const handleChangeType = () => setIsPasswordVisible(!isPasswordVisible);

    const isPassword = type === 'password';

    return (
      <InputContainer type={type}>
        {type === 'textarea' ? (
          <>
            <InputFieldTextarea
              placeholder='&nbsp;'
              hasIconRight={IconRight}
              name={name}
              disabled={disabled}
              defaultValue={defaultValue}
              ref={ref}
              onChange={onChange}
              type='textarea'
              maxLength={maxLength}
            ></InputFieldTextarea>
          </>
        ) : (
          <InputField
            placeholder='&nbsp;'
            hasIconRight={IconRight}
            type={isPassword ? (isPasswordVisible ? 'text' : type) : type}
            name={name}
            disabled={disabled}
            defaultValue={defaultValue}
            ref={ref}
            onChange={onChange}
            style={style}
          />
        )}
        <InputPlaceholder type={type}>
          {placeholder}
          {optional && <sup>(Opcional)</sup>}
        </InputPlaceholder>
        {maxLength && (
          <InputMaxLength>{`${length}/${maxLength}`}</InputMaxLength>
        )}
        {isPassword || IconRight ? (
          <InputRightIconContainer
            onClick={isPassword ? handleChangeType : null}
            isPassword={isPassword}
          >
            {isPassword ? (
              isPasswordVisible ? (
                <EyeOff height='20' width='20' color='var(--gray-2)' />
              ) : (
                <Eye height='20' width='20' color='var(--gray-2)' />
              )
            ) : (
              <IconRight height='16' width='16' color='var(--gray-2)' />
            )}
          </InputRightIconContainer>
        ) : null}
        <Error error={error} success={success} />
      </InputContainer>
    );
  }
);

export default React.memo(Input);
