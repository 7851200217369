import React from 'react';
// styles
import { LoaderCircleContainer, LoaderCircleComponent } from './loaderStyles';

const LoaderCircle = ({ size, color = 'var(--black)', stroke = 3 }) => {
  return (
    <LoaderCircleContainer
      size={size}
      viewBox='25 25 50 50'
      xmlns='http://www.wrc.org/2000/svg'
    >
      <LoaderCircleComponent
        cy='50'
        cx='50'
        r={20}
        fill='transparent'
        strokeWidth={stroke}
        stroke={color}
        strokeDasharray='1 400'
      ></LoaderCircleComponent>
    </LoaderCircleContainer>
  );
};

export default LoaderCircle;
