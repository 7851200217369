import React, { createContext, useState, useCallback } from 'react';
// styles
import {
  OverlayContainer,
  Overlay,
  ModalContainer,
  ModalWrapper,
  CrossIconContainer,
} from './modalStyles';
// icons
import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg';

export const ModalContext = createContext();

const centerModalOptions = {
  animationDuration: 200,
  positionX: 'center',
  positionY: 'center',
};

const rightModalOptions = {
  fullHeight: true,
  positionX: 'flex-end',
  animation: 'slide-right',
  animationDuration: 500, // ms
};

const Modal = ({ children }) => {
  const [modalConfig, setModalConfig] = useState({});

  const handleCloseModal = useCallback(() => {
    if (modalConfig.isClosable) {
      if (modalConfig?.open) {
        setModalConfig({ ...modalConfig, open: 0.5 });
        setTimeout(() => setModalConfig({}), modalConfig.animationDuration);
      }
    }
  }, [modalConfig]);

  const handleOpenModal = useCallback(props => {
    const options =
      props.type === 'full-right' ? { ...rightModalOptions } : { ...centerModalOptions };
    setModalConfig({
      isClosable: true,
      open: true,
      ...options,
      ...props,
    });
  }, []);

  const handleEscape = useCallback(
    e => {
      if (e.key === 'Escape') handleCloseModal();
    },
    [handleCloseModal]
  );

  return (
    <ModalWrapper tabIndex={0} onKeyDown={handleEscape}>
      <ModalContext.Provider
        value={{
          setModalConfig: props => handleOpenModal(props),
          closeModal: handleCloseModal,
        }}
      >
        {modalConfig?.open && (
          <OverlayContainer {...modalConfig}>
            <Overlay {...modalConfig} onClick={handleCloseModal} />
            <ModalContainer {...modalConfig} onClick={e => e.stopPropagation()}>
              {modalConfig.isClosable && (
                <CrossIconContainer onClick={handleCloseModal} fullHeight={modalConfig.fullHeight}>
                  <CrossIcon height='16' width='16' fill='var(--dark)' />
                </CrossIconContainer>
              )}
              {modalConfig.component}
            </ModalContainer>
          </OverlayContainer>
        )}

        {children}
      </ModalContext.Provider>
    </ModalWrapper>
  );
};

export default Modal;
