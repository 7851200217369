import * as constants from './newsletterConstants';

export const subscribeStart = payload => ({
  type: constants.SUBSCRIBE_START,
  payload,
});

export const subscribeSuccess = () => ({
  type: constants.SUBSCRIBE_SUCCESS,
});

export const subscribeFailure = payload => ({
  type: constants.SUBSCRIBE_FAILURE,
  payload,
});

export const cleanLogs = () => ({ type: constants.CLEAN_LOGS });
