import React, { useState, useContext } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { sendContactStart } from './contactActions';
// hooks
import { useForm } from 'react-hook-form';
// components
// components
import Input from '../../components/Base/Input/Input';
import Button from '../../components/Base/Button/Button';
// styles
import {
  FormModalContainer,
  FormModalLabel,
  ModalTitleCenter,
  FormModalSubContainer
} from '../../components/Modal/modalStyles';
// context
import { ModalContext } from '../../components/Modal/Modal';
import { useCallback } from 'react';

const Contact = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, getValues } = useForm();

  const { loading, error } = useSelector(({ contact }) => contact);

  const { closeModal } = useContext(ModalContext);

  const [messageLength, setMessageLength] = useState(0);

  const onSubmit = useCallback(
    data => {
      dispatch(sendContactStart(data));
      if (!error) closeModal();
    },
    [dispatch, closeModal, error]
  );

  const handleChangeMessage = () =>
    setMessageLength(getValues('message').length);

  return (
    <>
      <ModalTitleCenter>Contacto</ModalTitleCenter>
      <FormModalContainer onSubmit={handleSubmit(onSubmit)}>
        <FormModalSubContainer disabled={loading}>
          <FormModalLabel>
            <Input
              ref={register({
                required: true,
                minLength: 3
              })}
              name='name'
              type='text'
              placeholder='Nombre'
              error={
                errors.name?.type === 'minLength'
                  ? 'Elige un nombre más largo'
                  : errors.name
                  ? 'Nombre requerido'
                  : null
              }
            />
          </FormModalLabel>

          <FormModalLabel>
            <Input
              ref={register({
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
              })}
              name='email'
              type='text'
              placeholder='Email'
              error={
                errors.email?.type === 'pattern'
                  ? 'Email inválido'
                  : errors.email
                  ? 'Email requerido'
                  : error
                  ? error
                  : null
              }
            />
          </FormModalLabel>

          <FormModalLabel>
            <Input
              ref={register({
                required: true,
                minLength: 10,
                maxLength: 300
              })}
              name='message'
              type='textarea'
              placeholder='Déjanos tu mensaje'
              onChange={handleChangeMessage}
              maxLength='300'
              length={messageLength}
              error={
                errors.message?.type === 'minLength'
                  ? 'El mensaje es demasiado corto'
                  : errors.message?.type === 'maxLength'
                  ? 'El mensaje es demasiado largo'
                  : errors.message
                  ? 'Mensaje requerido'
                  : null
              }
            />
          </FormModalLabel>

          <Button title='Enviar' isLoading={loading} />
        </FormModalSubContainer>
      </FormModalContainer>
    </>
  );
};

export default Contact;
