import React, { useState, useCallback, useMemo } from 'react';
// redux
import { useSelector } from 'react-redux';
// styles
import { SearchModalContainer, SearchResultText } from './searchStyles';
import { ProductListContainer } from '../ProductItem/productItemStyles';
// components
import Input from '../Base/Input/Input';
import ProductItem from '../ProductItem/ProductItem';
// icons
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';

const Search = () => {
  const [search, setSearch] = useState('');

  const { list: products, loading } = useSelector(({ products }) => products);
  const { list: combos, loading: combosLoading } = useSelector(
    ({ combos }) => combos
  );

  const getSearchResults = useCallback(
    arr => {
      return arr.filter(product => {
        const searchValue = search.trim().toLowerCase();
        return (
          product?.name?.trim().toLowerCase().includes(searchValue) ||
          product?.tags?.some(tag =>
            tag.name.trim().toLowerCase().includes(searchValue)
          )
        );
      });
    },
    [search]
  );

  const searchProductsResult = useMemo(() => getSearchResults(products), [
    getSearchResults,
    products
  ]);
  const searchCombosResult = useMemo(() => getSearchResults(combos), [
    getSearchResults,
    combos
  ]);

  const handleOnChange = e => setSearch(e.target.value);

  return (
    <SearchModalContainer>
      <Input
        placeholder='Busca aquí'
        IconRight={SearchIcon}
        onChange={handleOnChange}
      />

      <ProductListContainer>
        {[...searchProductsResult, ...searchCombosResult].map(product => (
          <ProductItem
            key={product._id}
            size={{ default: 'xs' }}
            options={{ hasOptions: false }}
            {...product}
          />
        ))}
      </ProductListContainer>

      {loading || combosLoading ? (
        <SearchResultText>Cargando...</SearchResultText>
      ) : !searchProductsResult.length ? (
        <SearchResultText>
          No se han encontrado resultados para <br />
          <b>
            <i>"{search.trim()}"</i>
          </b>
        </SearchResultText>
      ) : null}
    </SearchModalContainer>
  );
};

Search.propTypes = {};

export default React.memo(Search);
