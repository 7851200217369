import React from 'react';
import { LoaderPageContainer, LoaderPageTitle } from './loaderStyles';
import { Loader } from './Loader';

const LoaderPage = ({ size = 35, title }) => (
  <LoaderPageContainer>
    <Loader size={size} color='var(--black)' />
    {title ? <LoaderPageTitle>{title}</LoaderPageTitle> : null}
  </LoaderPageContainer>
);

export default LoaderPage;
