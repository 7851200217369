import { all, fork, put, takeLatest } from 'redux-saga/effects';
import renderSnackbar from '../../utils/snackbar';

import * as constants from './newsletterConstants';
import * as actions from './newsletterActions';
import * as services from './newsletterServices';

function* subscribe({ payload }) {
  try {
    const { subscription } = yield services.subscribeToNewsletter(
      payload.email
    );
    if (subscription) {
      yield put(actions.subscribeSuccess());
      // FIXME: appears twice
      yield put(renderSnackbar('success', 'Suscrito correctamente!'));
      yield payload.closeModal();
    }
  } catch (err) {
    yield put(actions.subscribeFailure({ error: err.message }));
  }
}

function* subscribeSaga() {
  yield takeLatest(constants.SUBSCRIBE_START, subscribe);
}

export default function* newsletterSaga() {
  yield all([fork(subscribeSaga)]);
}
