import { createGlobalStyle } from 'styled-components';
// colors
import { colors, colorsRGB } from './assets/constants/colors';
// fonts
import SegoeUI from './assets/fonts/SegoeUI.ttf';
import SegoeUIBold from './assets/fonts/SegoeUIBold.ttf';

// normalize
import { normalize } from './components/Base/normalize';

const GlobalStyles = createGlobalStyle`
  ${normalize}

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    margin: 0;
  }


  @font-face {
      font-family: 'Segoe UI';
      src: url(${SegoeUI});
  }

  @font-face {
      font-family: 'Segoe UI Bold';
      src: url(${SegoeUIBold});
  }


  body {
    width: 100%;
    min-height: 100vh;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  :root {
    ${colors};
    ${colorsRGB};
  }


  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  fieldset {
    border: none;
    padding: 0;
  }
  * ,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  #root {
    min-height: 100vh;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  /* product screen */
  :root {
    --product-image-size: 500;
    --grid-columns: 6; /* calc(calc(var(--product-image-size) / 100) + 1) */
    --grid-lines: 7;
    --gap: 8;
    --row-width: calc(
      calc(
          var(--product-image-size) -
            calc(calc(var(--grid-columns) - 1) * var(--gap))
        ) / var(--grid-columns)
    );
    @media screen and (max-width: 1420px), (max-width: 959px), (max-width: 450px) {
        --product-image-size: 300;
        --grid-lines: 5;
        --grid-columns: 4;

    }
    @media screen and (max-width: 1200px) and (min-width: 960px), (max-width: 550px) and (min-width: 451px) {
        --product-image-size: 400;
        --grid-lines: 6;
        --grid-columns: 5;

    }
    
    @media screen and (max-width: 768px) and (min-width: 551px){
        --product-image-size: 500;
        --grid-lines: 7;
        --grid-columns: 6;

    }
    
    @media screen and (max-width: 330px) {
        --product-image-size: 200;
        --grid-lines: 4;
        --grid-columns: 3;

    }
}

`;

export default GlobalStyles;
