import styled, { css } from 'styled-components';
import { breakpoints } from '../../assets/constants/breakpoints';
import { motion } from 'framer-motion';

/* ==================================== PRODUCT HEADER ==================================== */
export const ProductHeaderContainer = styled.section`
  display: flex;

  @media screen and (max-width: ${breakpoints.sm}) {
    flex-direction: column;
  }
`;

export const ProductImagesContainer = styled.div`
  width: calc(var(--product-image-size) * 1px);
  display: grid;
  grid-template-columns: repeat(var(--grid-columns), 1fr);
  grid-gap: calc(var(--gap) * 1px);
  grid-auto-flow: row;
  grid-auto-rows: calc(var(--row-width) * 1px);

  @media screen and (max-width: ${breakpoints.sm}) {
    margin: auto;
  }

  div {
    background-color: var(--light-2);
    img {
      object-fit: contain;
    }
  }

  > div {
    grid-row: 1 / var(--grid-lines);
    grid-column: 1 / var(--grid-lines);

    cursor: auto;

    > img {
      object-fit: contain;
      height: 100% !important;
    }
  }
`;

export const ProductImage = styled.img`
  background-color: var(--light);
  width: 100%;
  height: 100%;
  padding: 0.1rem;
  object-fit: contain;
  cursor: pointer;
  border: 2px solid ${({ selected }) => (selected ? 'var(--primary)' : 'transparent')};
  border-radius: 0.5rem;

  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 0;
    border-width: 1px;
  }
`;

// product data
export const ProductDataContainer = styled.div`
  margin-left: 2rem;
  padding: 0.2rem 0;
  flex: 1;
  height: calc(var(--product-image-size) * 1px);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${breakpoints.sm}) {
    margin-left: 0;
    margin-top: 1.5rem;
    height: auto;
  }
`;

export const ProductTitle = styled.h1`
  font-size: 2.3rem;
  color: var(--dark);
  font-weight: 500;
  text-transform: uppercase;
  font-weight: 400;
  margin: 0;
  word-break: break-word;
  @media screen and (max-width: ${breakpoints.xxl}) {
    font-size: 1.8rem;
  }
`;

export const ProductPriceContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0;
  @media screen and (max-width: ${breakpoints.xxl}) {
    margin: 1.5rem 0;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    margin: 0.5rem 0 1rem;
  }
`;

export const ProductPrice = styled.p`
  font-size: 2rem;
  color: var(--dark);
  display: flex;
  align-items: center;

  @media screen and (max-width: ${breakpoints.xxl}) {
    font-size: 1.5rem;
  }
`;

export const HeartContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  margin-left: 2rem;
  margin-top: 0.2rem;
  cursor: pointer;
  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.5;
      cursor: default;
    `}
`;

// product cart options

export const ProductCartOptionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

// product  info
export const ProductInfoListContainer = styled.div`
  margin-top: auto;
  @media screen and (max-width: ${breakpoints.sm}) {
    margin-top: 3rem;
  }
`;

export const ProductInfoItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 1rem;
`;

export const ProductInfoItemText = styled.p`
  color: var(--black);
  font-weight: 400;
  font-size: 1rem;

  @media screen and (max-width: ${breakpoints.xxl}) {
    font-size: 0.95rem;
  }
`;

export const ProductInfoItemTitle = styled(ProductInfoItemText)`
  font-weight: 600;
  margin-right: 0.5rem;
`;

/* ==================================== PRODUCT CATEGORIES ==================================== */
export const ProductCategoriesContainer = styled.div`
  margin: 3rem 0;
`;

export const ProductCategoriesTitleContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: wrap;

  @media screen and (max-width: ${breakpoints.xs}) {
    justify-content: center;
  }
`;

export const ProductCategoriesTitle = styled.h2`
  font-size: 1.1rem;
  font-weight: 500;
  background-color: ${({ active }) => (active ? 'var(--primary)' : 'var(--light-2)')};
  color: ${({ active }) => (active ? '#fff' : 'var(--dark-2)')};
  cursor: pointer;
  padding: 0.7rem 2rem;
  margin-bottom: 0.5rem;
  min-width: 10rem;
  text-align: center;

  :first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  :nth-child(2) {
    border-left: 1px solid rgba(var(--dark-2-rgb), 0.05);
    border-right: 1px solid rgba(var(--dark-2-rgb), 0.05);
  }
  :last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  @media screen and (max-width: 529px) {
    font-size: 1rem;
    :first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
    :nth-child(2) {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-width: 0;
    }
    :last-child {
      border-radius: 0.25rem;
    }
  }
  @media screen and (max-width: 368px) {
    border-radius: 0.25rem;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 1rem;
  }
`;

export const ProductCategoriesTextContainer = styled.div`
  margin: 1rem 0 2rem;
`;

export const ProductCategoriesTextTitle = styled.h2`
  font-size: 1.2rem;
  font-weight: 550;
  line-height: 1.4;
  margin-bottom: 0.5rem;
`;

export const ProductCategoriesText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  margin: 0;
  white-space: pre-line;
  ${({ hasMargin }) =>
    hasMargin &&
    css`
      padding-left: 0.5rem;
    `}
`;
