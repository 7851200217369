import React, { Component } from 'react';
import styled from 'styled-components';
import { Typography, Button } from '@material-ui/core';

const Container = styled.div`
  height: 100vh;
  background-color: #f7f9fc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  static getDerivedStateFromError(error) {
    return {
      hasError: true
    };
  }
  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Typography
            variant='h4'
            style={{ color: '#202021', fontWeight: 500, margin: '8px' }}
          >
            Ooops!
          </Typography>
          <Typography
            variant='h6'
            style={{ fontSize: '17px', fontWeight: 600, margin: '6px' }}
          >
            Vaya, algo ha salido mal. intenta de nuevo
          </Typography>

          <Typography
            variant='subtitle2'
            gutterBottom
            style={{ margin: '6px 6px 1.5rem' }}
          >
            Si el error persiste comúnicate con nosotros.
          </Typography>

          <Button
            variant='contained'
            size='small'
            onClick={() => (window.location = '/')}
            disableElevation
          >
            Regresar
          </Button>
        </Container>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
