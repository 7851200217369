import styled, { css } from 'styled-components';
import { breakpoints } from '../../../assets/constants/breakpoints';
import { motion } from 'framer-motion';

const ButtonSizes = {
  xxl: {
    height: '46px',
    padding: '0.46rem 1rem'
  },
  xl: {
    height: '43px',
    padding: '0.46rem 1rem'
  },
  md: {
    height: '40px',
    padding: '0.4rem .9rem'
  },
  sm: {
    height: '36px',
    padding: '0.35rem .84rem'
  }
};

const ButtonTextSizes = {
  xxl: '15px',
  xl: '12.5px',
  md: '12px',
  sm: '11px'
};

export const Btn = styled(motion.button)`
  ${({ fullWidth }) =>
    fullWidth
      ? css`
          width: 100%;
        `
      : css`
          min-width: 10rem;
        `};
  border: 1px solid
    ${({ isLoading }) => (isLoading ? '#eaeaea' : 'transparent')};
  background-color: ${({ isLoading }) =>
    isLoading ? 'var(--light)' : 'var(--black)'};
  cursor: ${({ isLoading }) => (isLoading ? 'not-allowed' : 'pointer')};
  ${({ isLoading }) =>
    !isLoading &&
    css`
      box-shadow: 0 5px 12px rgba(0, 0, 0, 0.12);
    `};
  border-radius: 0.3rem;
  user-select: none;
  outline: 0;

  ${({ isLoading }) =>
    !isLoading &&
    css`
      :active {
        transform: scale(0.97);
      }
    `}

  ${({ size }) => css`
    padding: ${ButtonSizes[size.size].padding};
    height: ${ButtonSizes[size.size].height};
    @media screen and (max-width: ${breakpoints.xxl}) {
      padding: ${ButtonSizes[size.xxl].padding};
      height: ${ButtonSizes[size.xxl].height};
    }
    @media screen and (max-width: ${breakpoints.md}) {
      padding: ${ButtonSizes[size.md].padding};
      height: ${ButtonSizes[size.xxl].height};
    }
    @media screen and (max-width: ${breakpoints.sm}) {
      padding: ${ButtonSizes[size.sm].padding};
      height: ${ButtonSizes[size.sm].height};
    }
  `}
`;

export const ButtonText = styled.p`
  color: var(--white);
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;

  ${({ size }) => css`
    font-size: ${ButtonTextSizes[size.size]};
    @media screen and (max-width: ${breakpoints.xxl}) {
      font-size: ${ButtonTextSizes[size.xxl]};
    }
    @media screen and (max-width: ${breakpoints.md}) {
      font-size: ${ButtonTextSizes[size.md]};
    }
    @media screen and (max-width: ${breakpoints.sm}) {
      font-size: ${ButtonTextSizes[size.sm]};
    }
  `}
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
