import React, { useState, useCallback, useMemo } from 'react';
// styles
import {
  ProductCategoriesContainer,
  ProductCategoriesTitle,
  ProductCategoriesTitleContainer,
  ProductCategoriesText,
  ProductCategoriesTextContainer,
  ProductCategoriesTextTitle,
} from './productStyles';

const ProductCategories = ({ product }) => {
  const isCombo = product?.type === 'Combo';

  const text = useMemo(
    () =>
      isCombo
        ? {
            description: product?.products?.map(product => ({
              name: product?.name,
              text: product?.description,
            })),
            ingredients: product?.products?.map(product => ({
              name: product?.name,
              text: product?.ingredients,
            })),
            usage: product?.products?.map(product => ({
              name: product?.name,
              text: product?.usage,
            })),
          }
        : {
            description: [{ text: product.description }],
            ingredients: [{ text: product.ingredients }],
            usage: [{ text: product.usage }],
          },
    [isCombo, product]
  );

  const handleSetCategory = useCallback(
    category => {
      setActiveCategory({ title: category, text: text[category] });
    },
    [text]
  );

  const [activeCategory, setActiveCategory] = useState({
    title: 'description',
    text: text.description,
  });

  return (
    <ProductCategoriesContainer>
      <ProductCategoriesTitleContainer>
        <ProductCategoriesTitle
          active={activeCategory.title === 'description'}
          onClick={() => handleSetCategory('description')}
        >
          Descripción
        </ProductCategoriesTitle>
        <ProductCategoriesTitle
          active={activeCategory.title === 'ingredients'}
          onClick={() => handleSetCategory('ingredients')}
        >
          Ingredientes
        </ProductCategoriesTitle>
        <ProductCategoriesTitle
          active={activeCategory.title === 'usage'}
          onClick={() => handleSetCategory('usage')}
        >
          Modo de uso
        </ProductCategoriesTitle>
      </ProductCategoriesTitleContainer>

      {activeCategory?.text?.map(({ name, text }) => (
        <ProductCategoriesTextContainer key={Math.random()}>
          {name && <ProductCategoriesTextTitle>{name}</ProductCategoriesTextTitle>}

          <ProductCategoriesText hasMargin={name}>
            {text ? text : 'No se especifica'}
          </ProductCategoriesText>
        </ProductCategoriesTextContainer>
      ))}
    </ProductCategoriesContainer>
  );
};

export default React.memo(ProductCategories);
