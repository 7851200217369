import * as constants from './ordersConstants';

export const getOrdersStart = payload => ({
  type: constants.GET_ORDERS_START,
  payload
});

export const getOrdersSuccess = payload => ({
  type: constants.GET_ORDERS_SUCCESS,
  payload
});

export const getOrdersFailure = payload => ({
  type: constants.GET_ORDERS_FAILURE,
  payload
});

// get order
export const getOrderStart = payload => ({
  type: constants.GET_ORDER_START,
  payload
});

export const getOrderSuccess = payload => ({
  type: constants.GET_ORDER_SUCCESS,
  payload
});

export const getOrderFailure = payload => ({
  type: constants.GET_ORDER_FAILURE,
  payload
});

// Search order
// get order
export const searchOrderStart = payload => ({
  type: constants.SEARCH_ORDER_START,
  payload
});

export const searchOrderFailure = payload => ({
  type: constants.SEARCH_ORDER_FAILURE,
  payload
});
