import styled, { css } from 'styled-components';
import { breakpoints } from '../../assets/constants/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

// modals

const textStyles = css`
  line-height: 1.5;
  font-size: 1.1rem;
`;

export const ModalContainer = styled.div`
  padding: 0 1rem 1rem;

  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 0 1rem 0.25rem;
  }
`;

export const ModalMainText = styled.p`
  ${textStyles};
  margin: 2rem 0 1.5rem;
`;

export const ModalText = styled.p`
  ${textStyles};
  margin-bottom: 0.75rem;
`;

export const ModalTextClickable = styled.span`
  ${textStyles};
  margin-bottom: 0.75rem;
  cursor: pointer;
  color: var(--blue);
`;

export const ModalTextLink = styled.a`
  ${textStyles};

  color: var(--blue);
`;
