import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { clearCart } from '../Cart/cartActions';

import * as constants from './checkoutConstants';
import * as actions from './checkoutActions';
import * as services from './checkoutServices';

function* sendOrder({ payload }) {
  try {
    const { init_point } = yield services.sendOrder(payload);
    if (init_point) {
      yield put(actions.sendOrderSuccess());
      yield put(clearCart());
      window.location.href = init_point;
    }
  } catch (err) {
    yield put(actions.sendOrderFailure({ error: err.message }));
  }
}

function* sendOrderSaga() {
  yield takeLatest(constants.SEND_ORDER_START, sendOrder);
}

export default function* checkoutSaga() {
  yield all([fork(sendOrderSaga)]);
}
