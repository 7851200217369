import styled from 'styled-components';
import { breakpoints } from '../../assets/constants/breakpoints';

export const Container = styled.div`
  /* background-color: green; */
`;

export const CartList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
`;

// footer styles
export const CartFooterContainer = styled.div``;

export const TotalTitle = styled.h3`
  font-size: 1.75rem;
  font-weight: 500;
  margin-bottom: 0.5rem;

  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 1.5rem;
  }
`;

export const TotalText = styled.p`
  font-size: 2.5rem;
  margin-bottom: 0.5rem;

  @media screen and (max-width: ${breakpoints.sm}) {
    font-size: 2rem;
  }
`;

export const FooterText = styled.p`
  font-size: 1rem;
`;

export const ButtonsContainer = styled.div`
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  grid-gap: 0.5rem;
`;
