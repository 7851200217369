import styled from 'styled-components';

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 5rem;
`;

export const EmptyText = styled.p`
  font-size: 1.5rem;
  text-align: center;
  margin-top: 2rem;
`;
