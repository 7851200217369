import * as constants from './favoritesConstants';

const likeProduct = (state, productId, action, like) => {
  if (action === 'like')
    return {
      ...state,
      list: [...state.list, like],
      loading: false,
      error: null
    };
  else {
    let newList = [...state.list];
    newList = newList.filter(({ product }) => product._id !== productId);

    return { ...state, list: [...newList], loading: false, error: null };
  }
};

const initialState = {
  list: [],
  loading: false,
  error: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.GET_USER_FAVORITES_START:
      return { ...state, loading: true, error: null };
    case constants.LIKE_PRODUCT_START:
      return { ...state, loading: true, error: null };

    case constants.GET_USER_FAVORITES_SUCCESS:
      return {
        ...state,
        list: payload.list,
        loading: false,
        error: null
      };
    case constants.LIKE_PRODUCT_SUCCESS:
      return likeProduct(
        state,
        payload.productId,
        payload.action,
        payload.like
      );

    case constants.GET_USER_FAVORITES_FAILURE:
    case constants.LIKE_PRODUCT_FAILURE:
      return { ...state, loading: false, error: payload.error };

    default:
      return state;
  }
};
