import React, { useEffect, useContext } from 'react';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { loginStart, cleanLogs } from './authActions';
import { validateLogin } from './authValidations';
// hooks
import { useForm } from 'react-hook-form';
import useAuth from '../../hooks/useAuth';
// components
import Register from './Register';
import ForgotPassword from './ForgotPassword';
import Input from '../../components/Base/Input/Input';
import Button from '../../components/Base/Button/Button';
// styles
import {
  AuthTextsContainer,
  AuthText,
  AuthTextLink,
  AuthTextContainer
} from './authStyles';
import {
  FormModalContainer,
  FormModalLabel,
  ModalTitleCenter,
  FormModalSubContainer
} from '../../components/Modal/modalStyles';
// context
import { ModalContext } from '../../components/Modal/Modal';

const Login = () => {
  useAuth();

  const dispatch = useDispatch();
  const { register, handleSubmit, setError, errors } = useForm();

  const { loading, error } = useSelector(({ auth }) => auth);

  const { setModalConfig, closeModal } = useContext(ModalContext);

  const onSubmit = data => {
    if (!loading) {
      const isValid = validateLogin(data);
      if (isValid.valid) {
        dispatch(loginStart({ ...data, closeModal }));
      } else setError('error', '', isValid.message);
    }
  };

  useEffect(() => {
    return () => dispatch(cleanLogs());
  }, [dispatch]);

  const handleChangeModal = component =>
    !loading && setModalConfig({ component });

  return (
    <>
      <ModalTitleCenter>Iniciar Sesión</ModalTitleCenter>
      <FormModalContainer onSubmit={handleSubmit(onSubmit)}>
        <FormModalSubContainer disabled={loading}>
          <FormModalLabel>
            <Input
              ref={register}
              type='text'
              placeholder='Correo electrónico'
              name='email'
            />
          </FormModalLabel>

          <FormModalLabel>
            <Input
              ref={register}
              type='password'
              placeholder='Contraseña'
              name='password'
              error={error || errors.error?.message}
            />
          </FormModalLabel>

          <AuthTextsContainer>
            <AuthTextContainer>
              <AuthText>¿Aún no formas parte?</AuthText>
              <AuthTextLink onClick={() => handleChangeModal(<Register />)}>
                Registrate
              </AuthTextLink>
            </AuthTextContainer>

            <AuthTextContainer>
              <AuthTextLink
                onClick={() => handleChangeModal(<ForgotPassword />)}
              >
                ¿Olvidaste tu contraseña?
              </AuthTextLink>
            </AuthTextContainer>
          </AuthTextsContainer>
          <Button title='Ingresar' isLoading={loading} />
        </FormModalSubContainer>
      </FormModalContainer>
    </>
  );
};

export default Login;
