import * as constants from './authConstants';

const initialState = {
  user: {},
  isAuth: false,
  loading: false,
  error: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case constants.REGISTER_START:
    case constants.LOGIN_START:
    case constants.FORGOT_PASSWORD_START:
    case constants.RESET_PASSWORD_START:
      return { ...state, user: {}, loading: true, error: null };

    case constants.EDIT_USER_START:
      return { ...state, loading: true };

    case constants.EDIT_USER_SUCCESS:
      return {
        ...state,
        user: { ...state.user, profile: { ...payload.user.profile } },
        message: payload.message,
        loading: false,
        error: null
      };

    case constants.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };

    case constants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };

    case constants.LOGIN_SUCCESS:
      return {
        ...state,
        user: payload.user,
        isAuth: !!payload.user,
        loading: false,
        error: null
      };

    case constants.REGISTER_SUCCESS:
      return { ...state, loading: false, error: '' };

    case constants.GET_LIKES_SUCCESS:
      return {
        ...state,
        user: { ...state.user, favorites: payload.favorites }
      };

    case constants.REGISTER_FAILURE:
    case constants.LOGIN_FAILURE:
    case constants.FORGOT_PASSWORD_FAILURE:
    case constants.RESET_PASSWORD_FAILURE:
    case constants.EDIT_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error
      };

    case constants.LOGOUT:
      localStorage.removeItem('token');
      return { ...state, isAuth: false, user: {} };

    case constants.CLEAN_LOGS:
      return { ...state, error: null };

    default:
      return state;
  }
};
