import * as constants from './contactConstants';

export const sendContactStart = payload => ({
  type: constants.SEND_CONTACT_START,
  payload,
});

export const sendContactSuccess = payload => ({
  type: constants.SEND_CONTACT_SUCCESS,
  payload,
});

export const sendContactFailure = payload => ({
  type: constants.SEND_CONTACT_FAILURE,
  payload,
});
