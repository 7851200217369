import React from 'react';
import { ModalTitleCenter } from '../../components/Modal/modalStyles';
import { ModalContainer, ModalMainText, ModalText, ModalTextLink } from './homeStyles';

const Sends = () => {
  return (
    <ModalContainer>
      <ModalTitleCenter>Envíos</ModalTitleCenter>
      <ModalMainText>
        Nuestros envios se realizan mediante una moto express full asi te llega rapido a tu
        domicilio{' '}
        <span have role='img'>
          {' '}
          🔥
        </span>
      </ModalMainText>
      <ModalText>
        Los precios varian segun la localidad y se encuentran al terminar tu pedido.
      </ModalText>
      <ModalText>
        <strong>
          Envios al interior consultar por{' '}
          <ModalTextLink
            href='https://api.whatsapp.com/send?phone=541138793055&text=Hola! Quería consultar por los envíos hacia el interior!'
            target='_blank'
            rel='noopener noreferrer'
          >
            Whatsapp
          </ModalTextLink>
          .
        </strong>
      </ModalText>
      <ModalText>
        <strong>Importante:</strong> No trabajamos con el stock del local. Los pedidos se realizan
        una vez que tu pago fue confirmado, y se retiran en cuanto esten finalizados.
      </ModalText>
    </ModalContainer>
  );
};

export default Sends;
