import styled, { css } from 'styled-components';

export const InputContainer = styled.label`
  position: relative;
  margin: ${({ type }) => (type === 'textarea' ? '3rem' : '1.2rem')} 0 1.2rem;
  display: flex;
  align-items: center;
  width: 100%;
`;

const Input = css`
  padding: 0.5rem;
  padding-right: ${({ hasIconRight }) => (hasIconRight ? '2rem' : '0.5rem')};
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--gray-2);
  outline: 0;
  position: relative;
  width: 100%;
  font-size: 1rem;
  user-select: none;
  caret-color: var(--primary);
  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--gray);
    `};

  &:not(:placeholder-shown),
  &:focus {
    ${({ maxLength }) =>
      maxLength
        ? css`
            border-image: linear-gradient(to bottom, white 9.371rem, var(--primary)) 1 1;
          `
        : css`
            border-color: var(--primary);
          `}
  }

  &:not(:placeholder-shown) + p,
  &:focus + p {
    font-size: 0.9rem;
    left: 0;
    ${({ type }) =>
      type === 'textarea'
        ? css`
            top: -1.5rem;
          `
        : css`
            bottom: 2rem;
          `}

    color: var(--primary);
  }

  :-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px var(--light) inset;
    box-shadow: 0 0 0px 1000px var(--light) inset;
  }
`;

export const InputField = styled.input`
  ${Input};
`;

export const InputFieldTextarea = styled.textarea`
  ${Input};
  min-width: 100%;
  max-width: 100%;
  min-height: 100px;
  height: 150px;
  max-height: 200px;
  resize: none;
  padding-top: 0;
  ${({ maxLength }) =>
    maxLength &&
    css`
      border-bottom: 2rem solid white;
      border-image: linear-gradient(to bottom, white 9.3725rem, var(--black) 9.3725rem) 1 1;
    `}

  ::before {
    content: '';
    height: 3rem;
    width: 100%;
    background-color: red;
  }
`;

export const InputPlaceholder = styled.p`
  position: absolute;
  ${({ type }) =>
    type !== 'textarea'
      ? css`
          bottom: 0.8rem;
        `
      : css`
          top: -0.5rem;
        `};
  left: 0.5rem;
  font-size: 1rem;
  color: var(--gray-2);
  transition: font-size, bottom, left, 0.3s ease;
  cursor: text;
  user-select: none;

  sup {
    top: -0.15rem;
    margin-left: 0.3rem;
  }
`;

export const InputRightIconContainer = styled.div`
  position: absolute;
  right: 0;
  padding: 0.5rem;
  ${({ isPassword }) =>
    isPassword &&
    css`
      cursor: pointer;
    `}
`;

export const TextError = styled.span`
  position: absolute;
  color: ${({ type }) => (type === 'error' ? 'var(--red)' : 'var(--green)')};
  top: 105%;
  left: 0;
`;

export const InputMaxLength = styled.span`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  font-size: 0.8rem;
  color: var(--gray-2);
`;

// fieldset
export const FormSubContainer = styled.fieldset`
  max-width: 100%;
  :disabled {
    input {
      opacity: 0.7;
    }
  }
`;
