import React, { useContext } from 'react';
// context
import { ModalContext } from '../../components/Modal/Modal';
// components
import Contact from '../Contact/Contact';
// styles
import { ModalTitleCenter } from '../../components/Modal/modalStyles';
import { ModalContainer, ModalMainText, ModalTextLink, ModalTextClickable } from './homeStyles';

const Refund = () => {
  const { setModalConfig } = useContext(ModalContext);

  return (
    <ModalContainer>
      <ModalTitleCenter>POR MAYOR</ModalTitleCenter>
      <ModalMainText>
        Lo único que tienes que hacer es comunicarte con nosotros por{' '}
        <ModalTextLink
          href='https://api.whatsapp.com/send?phone=541138793055&text=Hola! Me gustaría hacer una compra por mayor!'
          target='_blank'
          rel='noopener noreferrer'
        >
          Whatsapp
        </ModalTextLink>{' '}
        o un email haciendo{' '}
        <ModalTextClickable onClick={() => setModalConfig({ component: <Contact /> })}>
          click aquí
        </ModalTextClickable>
        .
      </ModalMainText>
    </ModalContainer>
  );
};

export default Refund;
