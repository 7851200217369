import styled from 'styled-components';
import { breakpoints } from '../../assets/constants/breakpoints';
import { motion } from 'framer-motion';

export const ProductsList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3rem;
  align-items: stretch;
  margin: 4rem 0 3rem;

  @media screen and (max-width: ${breakpoints.vl}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: ${breakpoints.xxl}) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  @media screen and (max-width: ${breakpoints.md}) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 2rem;
    margin: 2rem 0 1.5rem;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    margin: 1.5rem 0 1rem;
    grid-gap: 2rem 1.5rem;
  }
  @media screen and (max-width: ${breakpoints.xxs}) {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  }
`;

// ==================================================== Product screen ==================================================== //

export const ProductContainer = styled(motion.section)`
  max-width: 1100px;
  margin: auto;
  height: 100%;

  @media screen and (max-width: ${breakpoints.xl}) {
    max-width: 900px;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    max-width: 100%;
  }
`;

export const RecommendedProductsContainer = styled.div`
  margin: 4rem 0;
`;

export const RecommendedProductsSubcontainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-gap: 3rem;
`;

export const RecommendedProductsTitle = styled.h2`
  font-size: 1.6rem;
  margin-bottom: 2rem;
`;

export const ErrorText = styled.p`
  text-align: center;
  margin: 3rem 0;
  font-size: 1.2rem;
`;
