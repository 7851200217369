import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';

// reducers
import authReducer from './containers/Auth/authReducer';
import newsletterReducer from './containers/Newsletter/newsletterReducer';
import notifierReducer from './containers/Notifier/notifierReducer';
import contactReducer from './containers/Contact/contactReducer';
import productsReducer from './containers/Products/productsReducer';
import cartReducer from './containers/Cart/cartReducer';
import checkoutReducer from './containers/Checkout/checkoutReducer';
import ordersReducer from './containers/Orders/ordersReducer';
import favoritesReducer from './containers/Favorites/favoritesReducer';
import combosReducer from './containers/Combos/combosReducer';
import sendsReducer from './containers/Sends/sendsReducer';

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['user', 'isAuth']
};

const cartPersistConfig = {
  key: 'cart',
  storage: sessionStorage,
  whitelist: ['list', 'price']
};

const favoritesPersistConfig = {
  key: 'favorites',
  storage: sessionStorage
};

const productsPersistConfig = {
  key: 'products',
  storage: sessionStorage
};

const combosPersistConfig = {
  key: 'combos',
  storage: sessionStorage
};

export default history =>
  combineReducers({
    auth: persistReducer(authPersistConfig, authReducer),
    newsletter: newsletterReducer,
    contact: contactReducer,
    notifier: notifierReducer,
    products: persistReducer(productsPersistConfig, productsReducer),
    combos: persistReducer(combosPersistConfig, combosReducer),
    checkout: checkoutReducer,
    orders: ordersReducer,
    sends: sendsReducer,
    cart: persistReducer(cartPersistConfig, cartReducer),
    favorites: persistReducer(favoritesPersistConfig, favoritesReducer),
    router: connectRouter(history)
  });
