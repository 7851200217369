export const REGISTER_START = 'app/Auth/REGISTER_START';
export const REGISTER_SUCCESS = 'app/Auth/REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'app/Auth/REGISTER_FAILURE';

export const LOGIN_START = 'app/Auth/LOGIN_START';
export const LOGIN_SUCCESS = 'app/Auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'app/Auth/LOGIN_FAILURE';

export const FORGOT_PASSWORD_START = 'app/Auth/FORGOT_PASSWORD_START';
export const FORGOT_PASSWORD_SUCCESS = 'app/Auth/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'app/Auth/FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_START = 'app/Auth/RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'app/Auth/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'app/Auth/RESET_PASSWORD_FAILURE';

export const EDIT_USER_START = 'app/Auth/EDIT_USER_START';
export const EDIT_USER_SUCCESS = 'app/Auth/EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'app/Auth/EDIT_USER_FAILURE';

export const GET_LIKES_START = 'app/Auth/GET_LIKES_START';
export const GET_LIKES_SUCCESS = 'app/Auth/GET_LIKES_SUCCESS';
export const GET_LIKES_FAILURE = 'app/Auth/GET_LIKES_FAILURE';

export const LOGOUT = 'app/Auth/LOGOUT';
export const CLEAN_LOGS = 'app/Auth/CLEAN_LOGS';
